import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles, StyledTab, StyledTabs, drawerWidth } from './styles';
import withWidth from '@material-ui/core/withWidth';
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Search from '@material-ui/icons/Search';
import Person from '@material-ui/icons/Person';
import History from '@material-ui/icons/History';
import Comment from '@material-ui/icons/Comment';
import Info from '@material-ui/icons/Info';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import PhoneDisabledTwoTone from '@material-ui/icons/PhoneDisabledTwoTone';
import AttachFile from '@material-ui/icons/AttachFile';
import MicNoneTwoTone from '@material-ui/icons/MicNoneTwoTone';
import Send from '@material-ui/icons/Send';
import Mood from '@material-ui/icons/Mood';
import Movie from '@material-ui/icons/Movie';
import { MessageBox, SystemMessage } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import GridItem from '../../components/Grid/GridItem';
import { DialogActions, DialogContent, Hidden, Popper, Tooltip } from '@material-ui/core';
import Card from '../../components/Card/Card';
import {CustomInput, Table} from '../../components';
import FlipMove from 'react-flip-move';
import GridContainer from '../../components/Grid/GridContainer';
import {
  dangerColor,
  grayColor,
  hexToRgb, hustColorPrimary,
  hustColorSecondary,
  infoColor, primaryBoxShadow,
  primaryColor,
  successColor,
  warningColor,
} from '../../assets/jss/material-dashboard-pro-react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  ArrowForward,
  Camera,
  CameraAlt,
  Check,
  Close,
  ExitToApp,
  ExpandMore,
  InsertDriveFile,
  KeyboardArrowLeft,
  MoreVert,
  PhoneEnabled,
  SyncAlt,
  Edit,
  Schedule,
  LockOutlined, HourglassEmpty, GroupAdd,
} from '@material-ui/icons';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Popover from '@material-ui/core/Popover';
import {
  convertBlobToBase64,
  converterTextoWpp,
  downloadFileAsBlob,
  getFotoLink,
  iconMessage,
  zeroEsquerda,
  formataNumeroTelefone,
  ls,
  undefinedFunction,
  alterarSenhaUsuario,
  uploadFile, getIAPromtResponseStream, fetchAsBlob,
} from '../../components/Funcoes';
import Swal from 'sweetalert2';
import { apiURL, convertDate, WebService } from '../../components/WebService';
import MicRecorder from 'mic-recorder-to-mp3';
import { Document, Page, pdfjs } from 'react-pdf';
import SyncLoader from 'react-spinners/SyncLoader';
import Dropzone from 'react-dropzone';
import defaultAvatar from '../../assets/img/default-avatar.png';
import Timer from 'react-compound-timer';
import { ChildButton, FloatingMenu } from 'react-floating-button-menu';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import { CustomButtons } from '../../components';
import { PersonAdd } from '@material-ui/icons';
import Perfil from './TabsInfo/Perfil';
import BlockIcon from '@material-ui/icons/Block';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../components/CustomButtons/Button';
import Webcam from 'react-webcam';
import { Lightbox } from 'react-modal-image';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FlashOn from '@material-ui/icons/FlashOn';
import Robot from '../../assets/img/Robot.jpg';
import backgroundNoChatSelected from '../../assets/img/SelecioneChat.png';
import audioNotification from '../../assets/audio/notification-001.mp3';
import ModalEndCalled from '../Modal/EndCalled';
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import api from '../../components/api';
import ModalScheduledMessages from '../Modal/ScheduledMessages';
import Historico from './TabsInfo/Historico';
import MessageBoxDocument from '../../components/v2/Chat/Message/MessageBoxDocument';
import { ar } from 'date-fns/locale';
import { checkBeta } from '../BetaControll';
import ModalUploadMediaFiles from '../Modal/UploadMedia/ModalUploadMediaFiles';
import ModalUploadMediaCamera from '../Modal/UploadMedia/ModalUploadMediaCamera';
import { AutoAwesome } from "../../assets/img/icons";
import { BsTextParagraph, BsMagic, BsCheck, BsStars, BsSendPlus } from "react-icons/bs";
import {MdBlock, MdClose, MdOutlineBlock, MdOutlineTaskAlt, MdShortText} from "react-icons/md";
import { GiMicrophone } from "react-icons/gi";
import {FaClock, FaPlus, FaRobot, FaTasks} from "react-icons/fa";
import TextSuggestion from "../Modal/AI/TextSuggestion";
import IllustrationPigMoney from "../../assets/img/illustration-pig-money-person.png";
import rankingImage from "../../assets/img/ranking.png";
import medal1st from "../../assets/img/medal/1st.webp";
import medal2st from "../../assets/img/medal/2st.webp";
import medal3st from "../../assets/img/medal/3st.webp";
import {Tabs, Tab} from '@material-ui/core';
import { CgAlarm } from "react-icons/cg";
import TaskViewer from "../Modal/Task/TaskViewer";
import {addRecipient, createTask, getTasks, TaskStatusLabel, removeTaskCalled, removeUser} from "../Tasks/taskHelper";
import TaskLabelStatus from "../Tasks/components/TaskLabelStatus";
import ViewUserActions from "../Tasks/components/ViewUserActions";
import { isPartner } from 'utils/validateIsPartner';
import {GoPersonAdd} from "react-icons/go";

const vCard = require('vcard-parser');
const whatsapp = 'https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-01-512.png';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const recorder = new MicRecorder({ bitRate: 128 });

const ackStatus = {
  0: 'ERROR',
  1: 'PENDING',
  2: 'SERVER',
  3: 'DELIVERY',
  4: 'READ',
  5: 'PLAYED',
};

const ackStatusCard = {
  // 0: '',
  1: 'waiting',
  2: 'sent',
  3: 'received',
  4: 'read',
  5: 'read',
};

let timeoutBuscaContatos;

const chatTypes = {
  image: 'photo',
  chat: 'text',
  location: 'photo',
  sticker: 'photo',
  document: 'file',
  // e2e_notification: 'sistema'
};

const chatTiposTraducao = {
  image: '🖼️ Imagem',
  video: '🎬 Video',
  vcard: '👤 Contato',
  ptt: '🔉 Audio',
  audio: '🔉 Mensagem de voz',
  location: '📍 Localização',
  sticker: '🖼️ Figurinha',
  document: '📄 Arquivo',
};

const EFlowStateStatus = {
  0: {color: dangerColor[0], label: 'Parado'},
  1: {color: infoColor[0], label: 'Em execução'},
  2: {color: warningColor[0], label: 'Aguardando'},
  3: {color: successColor[0], label: 'Finalizado'}
};

let refImagemAtendente, refInputUpload, timeoutBuscaContato;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const CardProfile = ({
  nome,
  telefone,
  ultimaMensagem,
  fotoPerfil,
  registroDiaHora,
  mensageiro,
  ativo,
  onClick,
  enviado,
                       flagExcluida,
  mensagensNaoLidas,
  disabled,
  bot,
  tasks
}) => {
  const classes = useStyles();

  const countCaractere = text => {
    if (!text) return '';
    const maxLength = 40;
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  };

  return (
    <List component="nav" onClick={onClick} className={classes.nav} style={{ padding: 0, opacity: disabled ? 0.4 : 1 }}>
      <ListItem
        button
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: '20px 20px 0 20px',
          backgroundColor: ativo ? `rgba(${hexToRgb('#161616')}, 0.2)` : undefined,
          // backgroundColor: ativo ? `rgba(55, 55, 55, 1)` : undefined
        }}
      >
        <ListItemAvatar>
          <Badge
            badgeContent={<img className={classes.badgeMensageiro} src={iconMessage(mensageiro)} alt={mensageiro} />}
          >
            <img alt={nome} src={fotoPerfil} className={classes.chatListProfilePicture} />
          </Badge>
        </ListItemAvatar>
        <ListItemText
          style={{ marginTop: 0, marginBottom: 0 }}
          primary={
            <Typography component="span">
              {nome} <br />
              <small>{telefone}</small>
              <div style={{ position: 'absolute', width: 'calc(100% - 130px)', height: 16, top: 42, display: 'flex' }}>
                {[
                  {
                    title: bot ? `BOT em execução [${bot.id} - ${bot.name}]` : '',
                    color: successColor[0],
                    icon: FaRobot,
                    enabled: !!bot,
                    blink: true,
                  },
                  {
                    title: `Existem tarefas para este chamado`,
                    color: infoColor[0],
                    icon: MdOutlineTaskAlt,
                    enabled: ((tasks || []).filter(el => el.type === 'task') || []).length,
                  },
                  {
                    title: `Lead`,
                    color: warningColor[0],
                    icon: GoPersonAdd,
                    enabled: ((tasks || []).filter(el => el.type === 'lead') || []).length,
                    showLabel: true,
                  },
                ]
                  .filter(el => el.enabled)
                  .map((prop, key) => (
                    <Tooltip title={prop.title} key={key}>
                      <span
                        style={{
                          ...(prop.blink ? { animation: 'blinker 2s infinite' } : {}),
                          height: 16,
                          maxWidth: 190,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: 12,
                          background: `rgba(${hexToRgb(prop.color)}, 0.15)`,
                          color: prop.color,
                          borderRadius: 10,
                          fontWeight: 600,
                          padding: '0 10px',
                        }}
                      >
                        <prop.icon />
                        {prop.showLabel ? prop.title : ''}
                        {/*{bot.id} - {bot.name}*/}
                      </span>
                    </Tooltip>
                  ))}
              </div>
            </Typography>
          }
          secondaryTypographyProps={bot ? { style: { marginTop: -4, marginBottom: 20 } } : null}
          secondary={
            <>
              <Typography component="span" variant="body2">
                {enviado ? <Check style={{ float: 'left', fontSize: 20 }} /> : ''}
                {flagExcluida ? (
                  <>
                    {' '}
                    <MdBlock /> Mensagem excluída{' '}
                  </>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: converterTextoWpp(countCaractere(ultimaMensagem)),
                    }}
                  />
                )}
              </Typography>
              <Badge
                badgeContent={mensagensNaoLidas || 0}
                max={99}
                className={classes.badgeNotificacao}
                classes={{}}
                color={'error'}
                // style={{background: `linear-gradient(0deg, ${primaryColor[0]}, ${primaryColor[2]})`}}
              />
              {registroDiaHora && (
                <small variant="caption" className={classes.boxTextHeader}>
                  {new Date().getDate() - new Date(registroDiaHora).getDate() === 1 &&
                  new Date().getMonth() === new Date(registroDiaHora).getMonth()
                    ? 'Ontem'
                    : convertDate(
                        registroDiaHora,
                        false,
                        false,
                        new Date(registroDiaHora).getDate() === new Date().getDate(),
                      )}
                </small>
              )}
            </>
          }
        />
      </ListItem>
      <Divider component="li" className={classes.divider} />
    </List>
  );
};

const FunctionalArticle = forwardRef((props, ref) => (
  <div ref={ref} key={props.key}>
    <CardProfile {...props} key={undefined} />
  </div>
));

const CardIconDescricao = ({ icon, label, color }) => {
  color = !color ? 'primary' : color;
  const arrayColors = {
    info: infoColor,
    primary: primaryColor,
    success: successColor,
    gray: grayColor,
  };

  return (
    <span
      style={{
        color: '#FFF',
        background: `linear-gradient(90deg, ${arrayColors[color][0]}, ${arrayColors[color][1]})`,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        height: 17,
        borderRadius: 5,
        fontWeight: 400,
        marginRight: 5,
        display: 'inline-block',
        textAlign: 'center',
        maxWidth: 200,
      }}
    >
      <i className={icon} />
      &nbsp;
      {label}
    </span>
  );
};

function NewChat({ width, webSocket, history, assets: { nomeAplicacao } }) {
  const classes = useStyles({ sent: true });

  const isMobile = ['xs', 'sm'].includes(width);
  const [valueTabInfo, setValueTabInfo] = React.useState(0);
  const [value, setValue] = useState(0);
  const [totalTesteExcluir, setTotalTesteExcluir] = useState(3);
  const [opcoesMenuAtendente, setOpcoesMenuAtendente] = useState({
    ref: null,
    open: false,
  });
  const [open, setOpen] = useState(['sm', 'xs'].includes(width));
  const [chamado, setChamado] = useState();
  const [openn, setOpenn] = useState(false);
  const [page, setPage] = useState(0);
  const [sendingMessage, setSendingMessage] = useState(false);

  const [openModalEndCalled, setOpenModalEndCalled] = useState(false);
  const [openModalScheduledMessages, setOpenModalScheduledMessages] = useState(false);

  const [filesDescription, setFilesDescription] = useState({
    lastFocusKey: 0,
    descriptions: {},
  });
  const [notifyGroup, setNotifyGroup] = useState(false);

  const handleClose = () => {
    setOpenMediaOpcoes(false);
  };

  const handleOpen = () => {
    setOpenMediaOpcoes(true);
  };

  const [tags, setTags] = useState([]);

  const getTags = async () => {
    const { data } = await api.get('/tag');
    setTags(data);
  };

  const [bots, setBots] = useState([]);
  const getBots = async () => {
    const { data } = await api.get('/bot', { params: { raw: false } });
    setBots(data.filter(el => el.config.enabled && el.config.callerType === 'Campaign'));
  };

  const getApplicationConfig = async () => {
    try {
      const { data } = await api.get('/application/config', {
        params: { config: 'flag_notificate_group_messages' },
      });
      // console.log(JSON.parse(data)); // TODO - Não exibir logs em produção
      setNotifyGroup(JSON.parse(data));
    } catch (e) {}
  };

  useEffect(() => {
    getTags();
    getBots();
    getApplicationConfig();
    const loadChatsFocus = () => {
      console.log('Carregando dados do chat');
      loadChat();
    };

    // - - -
    const keysStorage = Object.keys(localStorage).filter(el => el.startsWith('chat_message_cache:'));
    keysStorage.forEach(prop => {
      const { text, date } = JSON.parse(localStorage.getItem(prop));
      if (!text || ((new Date() - new Date(date).getTime()) > 24 * 60 * 60 * 1000)) {
        localStorage.removeItem(prop);
      }
    });
    // - - -

    if (window.isNativeApp) {
      window.addEventListener("focus", loadChatsFocus);
    }
    return () => {
      window.removeEventListener('focus', loadChatsFocus);
    }
  }, []);

  const handleChange = (event, newValue) => setValue(newValue);
  const handleDrawer = () => setOpen(!open);

  const refInputTextChat = useRef();

  // - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - *
  const [modalArquivosOpen, setModalArquivosOpen] = useState(false);
  const [modalCapturarOpen, setModalCapturarOpen] = useState(false);
  const [modalAdicionarContato, setModalAdicionarContato] = useState(false);

  const [novoContato, setNovoContato] = useState({
    telefone: '',
    nome: '',
    fotoPerfil: '',
    id_contato: '',
    id_whatsapp: '',
  });

  const [openEmoji, setOpenEmoji] = useState(false);
  const [openIAMenu, setOpenIAMenu] = useState(false);
  const [openBotList, setOpenBotList] = useState(false);
  const [showFabScroll, setShowFabScroll] = useState(false);
  const [openMediaOpcoes, setOpenMediaOpcoes] = useState(false);
  const [openChamadoOpcoes, setOpenChamadoOpcoes] = useState(false);

  const [chatAtivo, setChatAtivo] = useState(0);
  const [chat, setChat] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const [me, setMe] = useState({ nome: '', foto_perfil: '', id_usuario: '' });
  const [chatCarregados, setChatCarregados] = useState({});
  const [contatos, setContatos] = useState([]);
  const [contatosCarregados, setContatosCarregados] = useState([]);
  const [contactsPage, setContactsPage] = useState(0);
  const [grupos, setGrupos] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentosUsuario, setDepartamentosUsuario] = useState([]);
  const [mensagensPredefinidas, setMensagensPredefinidas] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [valueTabEspera, setValueTabEspera] = useState(0);
  const [openMessageSchedulerPicker, setOpenMessageSchedulerPicker] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [tasksCalled, setTasksCalled] = useState([]);
  const [timeMessageSchedulerPicker, setTimeMessageSchedulerPicker] = useState(
    new Date(new Date(new Date().setMinutes(0)).setHours(new Date().getHours() + 2)),
  );

  const [loadedChat, setLoadedChat] = useState(0);
  const [openModalIndicacao, setOpenModalIndicacao] = useState(false);
  const [modalIndicacaoTab, setModalIndicacaoTab] = useState(0);
  const [indicacaoRanking, setIndicacaoRanking] = useState([]);
  useEffect(() => {
    if (!openModalIndicacao) setModalIndicacaoTab(0);
  }, [openModalIndicacao]);

  useEffect(() => {
    (async () => {
      const {ok, ranking} = await WebService('/usuario/getRankingIndicacao');
      if (!ok) return;
      setIndicacaoRanking(ranking);
    })();
  }, []);

  const [idMensagemResposta, setIdMensagemResposta] = useState({
    id: null,
    texto: null,
  });
  const [text, setText] = useState('');
  const [textSugestionAI, setTextSugestionAI] = useState('');

  const [popOpcoesMensagem, setPopOpcoesMensagem] = useState({
    open: false,
    ref: null,
    id_mensagem: null,
    mensagem: null,
  });
  const [loading, setLoading] = useState(false);
  const [modalImagem, setModalImagem] = useState({ open: false, url: '' });
  const [gravarAudio, setGravarAudio] = useState({ gravando: false });

  const [refCardContatos, setRefCardContatos] = React.useState(null);
  const [conexoes, setConexoes] = React.useState(null);
  const [colorsContact, setColorsContact] = React.useState(
    JSON.parse(localStorage.getItem('colors-contacts-group') || '{}'),
  );

  const [modalTransferirChamado, setModalTransferirChamado] = useState({
    id_departamento: '',
    id_usuario: '',
    id_chamado: '',
  });

  useEffect(() => {
    localStorage.setItem('colors-contacts-group', JSON.stringify(colorsContact));
  }, [colorsContact]);

  const onKeyPress = ({ keyCode }) => {
    // Pressionou ESC
    if (keyCode === 27) setChatAtivo(0);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', onKeyPress, false);
    };
  }, []);

  const updateAudioCaption = async mensagens => {
    if (!mensagens) return;
    const audioMessages = mensagens.filter(el => el.tipo === 'audio' && !el.caption);
    for (let i = 0; i < audioMessages.length; i += 1) {
      const { id_mensagem, data } = audioMessages[i];

      if (!id_mensagem) continue;
      // Ignora caso seja antigo
      if (!data) continue;
      if (new Date().getTime() - new Date(data).getTime() > 2 * 60 * 60 * 100) continue;

      try {
        const { data } = await api.get(`/message/${id_mensagem}`);
        const { caption } = data;
        if (caption) {
          setChat(chat => {
            const findKey = chat[chatAtivo].mensagens.map(prop => prop.id_mensagem).indexOf(id_mensagem);
            chat[chatAtivo].mensagens[findKey].caption = caption;
            return { ...chat };
          });
        }
        // console.log({ data }); // TODO - Não exibir logs em prod
      } catch (e) {}
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (chatAtivo && chat[chatAtivo]) updateAudioCaption(chat[chatAtivo].mensagens);
    }, 5000);

    return () => clearInterval(interval);
  }, [chatAtivo, chat]);

  const [openRespostasRapidas, setOpenRespostasRapidas] = React.useState(false);
  const handleOpenRespostasRapidas = () => setOpenRespostasRapidas(!openRespostasRapidas);

  const [filter, setFilter] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorIA, setAnchorIA] = React.useState(null);
  const [anchorBOT, setAnchorBOT] = React.useState(null);

  const transferirAtendimento = async () => {
    const { ok } = await WebService(`/chamado/transferirAtendimento`, modalTransferirChamado, 'POST');

    if (ok) {
      setModalTransferirChamado({
        id_departamento: '',
        id_usuario: '',
        id_chamado: '',
      });
      setChatCarregados(cc => {
        delete cc[chatAtivo];
        return cc;
      });
      setChat(c => {
        delete c[chatAtivo];
        return c;
      });
      setChatAtivo(0);
      await Swal.fire({
        icon: 'success',
        title: 'O atendimento foi transferido com sucesso!',
      });
    }
  };

  useEffect(() => {
    // const keysRespostasRapidas = mensagensPredefinidas.map(prop => `/${prop['chave']}`);
    // setOpenRespostasRapidas(text && text[0] === "/" && ((text.length === 1) || (keysRespostasRapidas.indexOf(text) > -1)));
    setOpenRespostasRapidas(text && text[0] === '/');
    if (chatAtivo)
      localStorage.setItem(`chat_message_cache:${chatAtivo}`, JSON.stringify({ text, date: new Date() }));
  }, [text]);

  const receberStatusWS = async ({ data: JsonData }) => {
    const {
      ack,
      data,
      id_contato_fk,
      body,
      tipo,
      id_mensagem_whatsapp,
      id_whatsapp_conexao_fk,
      localizacao,
      id_mensagem,
      caption,
      uuid,
      metodo,
      id_contato,
      id_departamento,
      mensagemResposta,
      revoked,
      membro_grupo_nome,
      membro_grupo_numero,
      usuario,
      raw_message_data,
      id_message,
      chamado,
      calledBotData,
      editions
    } = JSON.parse(JsonData);
    let {flag_enviado} = JSON.parse(JsonData);

    const c = chat;
    if (calledBotData) {
      const {calledID, history, runningBOT} = data;
      setChat(chat => {
        let contactID = null;
        Object.keys(chat).forEach(prop => {
          if (Number(chat[prop].id_chamado) === Number(calledID)) contactID = prop;
        });
        console.log(contactID, calledID);
        if (contactID && chat[contactID].botsData) {
          chat[contactID].botsData.runningBOT = runningBOT;
          chat[contactID].botsData.history = history;
        }
        return {...chat};
      });
    } else if (revoked !== undefined) {
      if (c[id_contato]) {
        const mensagemKey = c[id_contato].mensagens
          .map(prop => parseInt(prop.id_mensagem))
          .indexOf(parseInt(id_mensagem));
        if (mensagemKey !== undefined) {
          c[id_contato].mensagens[mensagemKey] = {
            ...c[id_contato].mensagens[mensagemKey],
            flag_excluida: true,
          };
          setChat({ ...c });
        }
      }
    } else if (ack || editions) {
      if (c[id_contato_fk || id_contato]) {
        const mensagemKey = c[id_contato_fk || id_contato].mensagens
            .map(prop => parseInt(prop.id_mensagem))
            .indexOf(parseInt(id_mensagem));

        // Verificar se existe edições e pegar a última
        if (mensagemKey) {
          const messageParams = {};

          if (editions && editions.length) {
            const ultimaEdicao = editions[editions.length - 1];
            // Se  existe a última edição, atualizar o body com o texto mais recente
            if (ultimaEdicao) {
              messageParams.mensagem = ultimaEdicao.newText;  // Atualizar o body com o texto mais recente
              messageParams.edicoes = editions;  // Atualizar o body com o texto mais recente
            }
          }
          if (ack) messageParams.ack = ack;

          // Atualzia o state com os novos parâmetros
          c[id_contato_fk || id_contato].mensagens[mensagemKey] = {
            ...c[id_contato_fk || id_contato].mensagens[mensagemKey],
            ...messageParams
          };

          setChat({ ...c });
        }
      }
    } else if (['pong', 'removerChatEspera'].indexOf(metodo) === -1) {
      // Tratar mensagem individual
      let notificado = false;
      if (!c[id_contato_fk]) {
        if (id_contato_fk.toString().includes('@g.us')) return;
        const { ok, contato, id_chamado } = await WebService(`/contato/getContato`, { id_contato: id_contato_fk });
        if (!ok) window.location.reload();
        if (!id_chamado) return;

        c[id_contato_fk] = {
          ...contato,
          uuidConexao: uuid,
          mensageiro: 'whatsapp',
          dataUltimaMensagem: new Date(data),
          id_whatsapp_conexao: id_whatsapp_conexao_fk,
          id_whatsapp_conexao_fk,
          id_departamento_fk: id_departamento,
          id_departamento,
          id_chamado,
          mensagens: [],
        };
        /*document.addEventListener('DOMContentLoaded', function () {
          if (!Notification) {
            alert('Desktop notifications not available in your browser. Try Chromium.');
            return;
          }
          if (Notification.permission !== 'granted') Notification.requestPermission();
        });*/

        if (window.Notification.permission !== 'granted') window.Notification.requestPermission();
        else {
          refAudioNotification && refAudioNotification.current && refAudioNotification.current.play();

          const notification = new Notification(`:: ${nomeAplicacao} - Atendimento pendente ::`, {
            icon: getFotoLink(contato.foto_perfil),
            body: `Existe um novo atendimento pendente de ${contato.nome}`,
            closeTime: null,
            requireInteraction: true,
          });
          notification.onclick = () => {
            setActiveTab(contato.flag_atendimento ? 0 : 1);
            setChatAtivo(id_contato);
            window.focus();
          };
          notificado = true;
        }
      }

      const original_flag_enviado = !!flag_enviado;

      const { foto_perfil, nome, id_contato, flag_atendimento } = c[id_contato_fk];
      if (id_contato_fk.toString().includes('g.us')) {
        const grupo = grupos.find(el => el.id_grupo_whatsapp === id_contato_fk.toString());
        const {config} = grupo;
        if (config && config.type === 'private' && usuario)
          flag_enviado = (usuario.id_usuario === me.id_usuario);
        if (!notifyGroup) notificado = true;
      }
      if (!document.hasFocus() && !flag_enviado) {
        if (!notificado) {
          if (window.Notification.permission !== 'granted') window.Notification.requestPermission();
          else {
            // Busca os dados do grupo atual
            const currentGroup = grupos.find(grupo => grupo.id_grupo_whatsapp === id_contato_fk);

            if (currentGroup) { // Lógica para evitar notificação caso seja mensagem de grupo e o usuário não faça parte
              // Busca os dados do usuário atual
              const {id_departamento_fk, id_usuario} = usuarios.find(el => el.id_usuario === me.id_usuario) || {};
              const userDepartments = (id_departamento_fk || '').split(',').map(prop => Number(prop)); // Define os departamentos do usuário

              // Valida se o mesmo é gestor
              const flag_gestor = JSON.parse(localStorage.getItem('usuario_gestor') || 'false');

              if (!flag_gestor) {// Só valida se o usuário NÃO for gestor
                if (currentGroup.id_department_fk) { // Se tiver alguma validação de restrição por departamento
                  // Verifica se o usuário faz parte do departamento restringido
                  const permissionDepartment = userDepartments.includes(Number(currentGroup.id_department_fk));
                  if (!permissionDepartment) return;
                }

                if (currentGroup.id_user_fk) { // Se tiver alguma validação de restrição por usuário
                  const permissionUser = Number(id_usuario);
                  if (!permissionUser) return;
                }
              }
            }

            refAudioNotification && refAudioNotification.current && refAudioNotification.current.play();
            const notification = new Notification(`🤖 ${nomeAplicacao} - Nova mensagem`, {
              // icon: `http${configHost.protocol}://${configHost.baseURL}:3000/favicon.png`,
              icon: getFotoLink(foto_perfil),
              body: `💬 Você tem uma nova mensagem de ${nome}`,
              closeTime: null,
              requireInteraction: true,
            });
            notification.onclick = () => {
              setActiveTab(flag_atendimento ? 0 : 1);
              setChatAtivo(id_contato);
              window.focus();
            };
          }
        }
      }
      if (id_departamento) {
        c[id_contato_fk].id_departamento = id_departamento;
        c[id_contato_fk].id_departamento_fk = id_departamento;
      }

      // TODO - Verificar se já existe uma mensagem com o mesmo id_mensagem_whatsapp
      const keyMessageSentExists = id_message ? c[id_contato_fk].mensagens.map(prop => prop.id).indexOf(id_message) : -1;
      const keyMessageExists = id_mensagem ? c[id_contato_fk].mensagens.map(prop => prop.id_mensagem).indexOf(id_mensagem) : -1;

      flag_enviado = !!original_flag_enviado;
      const message = {
        tipo,
        id_message,
        mensagem: body,
        data: new Date(data),
        flag_enviado,
        id_mensagem,
        localizacao: JSON.parse(localizacao),
        id_contato_fk,
        id_mensagem_whatsapp,
        id_whatsapp_conexao_fk,
        mensagemResposta,
        caption,
        membro_grupo_nome,
        membro_grupo_numero,
        usuario,
        raw_message_data: raw_message_data ? JSON.parse(raw_message_data) : null,
        mediaURL: `https://storage.hustapp.com/midias/${id_mensagem_whatsapp}`,
      };

      if (keyMessageExists === -1 && keyMessageSentExists === -1) c[id_contato_fk].mensagens.push(message)
      else {
        const keyMessage = keyMessageExists > -1 ? keyMessageExists : keyMessageSentExists;
        const { mediaURL } = c[id_contato_fk].mensagens[keyMessage];
        if (mediaURL) message.mediaURL = mediaURL;
        c[id_contato_fk].mensagens[keyMessage] = message;
      }

      delete c[id_contato_fk].mensagens[c[id_contato_fk].mensagens.map(el => el.tipo).indexOf('loading')];
      c[id_contato_fk].mensagens = c[id_contato_fk].mensagens.filter(el => el);
      if (new Date(c[id_contato_fk].dataUltimaMensagem) < new Date(data))
        c[id_contato_fk].dataUltimaMensagem = new Date(data);

      // console.log({ chamado }); // TODO - Não exibir logs em prod

      if (chamado) {
        try {
          c[id_contato_fk].id_whatsapp_conexao_fk = chamado.id_whatsapp_conexao_fk;
          c[id_contato_fk].id_whatsapp_conexao = chamado.id_whatsapp_conexao_fk;
          c[id_contato_fk].uuid = conexoes.find(el => el.id_whatsapp_conexao === chamado.id_whatsapp_conexao_fk).uuid;
        } catch (e) {
          // Só para evitar qualquer crash por qualquer bug que possa existir
        }
      }

      chatCarregados[id_contato_fk] = c[id_contato_fk];

      setChatCarregados({ ...chatCarregados });

      const div = document.getElementById('div-chat-principal');
      setChatAtivo(chatAtivo => {
        if (div && chatAtivo === id_contato_fk) div.scrollTo(0, 90 * 90 * 90 * 90);
        return chatAtivo;
      });

      setChat({ ...c });
    }else if (metodo === 'removerChatEspera') {
      setChatAtivo(ca => (parseInt(id_contato) === ca ? 0 : ca));

      const tempChatCarregado = { ...chatCarregados };
      const tempChat = { ...chat };

      delete tempChat[id_contato];
      delete tempChatCarregado[id_contato];

      setChat(tempChat);
      setChatCarregados(tempChatCarregado);
    }
  };

  const filtrarArray = arr => {
    arr = arr.filter(el => el);
    arr = arr.filter(item =>
      Object.keys(item).some(key => {
        if (!item[key]) return false;
        return item[key]
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase());
      }),
    );
    return arr;
  };

  const finalizarAtendimento = async (resume, startEnd = false) => {
    const params = { id_contato: chatAtivo, resume, startEnd };
    const { ok } = await WebService(`/chamado/finalizarChamado`, params, 'POST');
    if (ok) {
      setOpenModalEndCalled(false);
      setChatAtivo(0);
      if (!startEnd) {
        delete chat[chatAtivo];
        delete chatCarregados[chatAtivo];
      } else {
        chat[chatAtivo].status = 'iniciado_finalizacao';
        chatCarregados[chatAtivo].status = 'iniciado_finalizacao';
      }

      setChat({ ...chat });
      setChatCarregados({ ...chatCarregados });

      Swal.fire({
        title: 'Atendimento finalizado com sucesso!',
        icon: 'success',
      });
    }
  };

  const [tabEspera, setTabEspera] = useState(0);

  const recusarAtendimento = async () => {
    const { id_chamado } = chat[chatAtivo];

    const clearAttendance = () => {
      delete chat[chatAtivo];
      delete chatCarregados[chatAtivo];
      setChatAtivo(0);
      setChat({ ...chat });
      setChatCarregados({ ...chatCarregados });
    };

    if (!id_chamado) return clearAttendance();

    const { value } = await Swal.fire({
      icon: 'question',
      title: 'Tem certeza que deseja recusar o atendimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    });

    if (value) {
      const { ok } = await WebService(`/chamado/finalizarChamado`, {
        id_chamado,
        flag_silencioso: true,
      });
      if (ok) {
        clearAttendance();
        await Swal.fire({
          title: 'Atendimento recusado com sucesso!',
          icon: 'success',
        });
      }
    }
  };

  const iniciarAtendimento = async () => {
    const { id_departamento_fk, uuidConexao } = chat[chatAtivo];
    const { ok, id_chamado, codigo_erro } = await WebService(
      `/chamado/iniciarChamado`,
      {
        id_contato: chatAtivo,
        id_departamento_fk,
        uuidConexao,
      },
      'POST',
    );

    if (ok) {
      setChat(c => {
        c[chatAtivo].flag_atendimento = true;
        c[chatAtivo].id_chamado = id_chamado;
        return c;
      });
      setChatCarregados(c => {
        c[chatAtivo].flag_atendimento = true;
        c[chatAtivo].id_chamado = id_chamado;
        return c;
      });
      setActiveTab(0);
    } else {
      if (codigo_erro === 54678) {
        const tempChat = { ...chat };
        const tempChatCarregados = { ...chatCarregados };
        delete tempChat[chatAtivo];
        delete tempChatCarregados[chatAtivo];
        setChatAtivo(0);
        setChat(tempChat);
        setChatCarregados(tempChatCarregados);
      }
    }
  };

  const gravarAudioF = async (enviar = true) => {
    if ((await navigator.permissions.query({ name: 'microphone' })).state !== 'granted') {
      try {
        await Swal.fire({
          title: 'Você deve fornecer permissão para acesso ao microfone.',
          text: 'Será aberto um popup do navegador solicitando a permissão.',
          icon: 'info',
        });
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (e) {
        await Swal.fire({
          title: 'O sistema não possui permissão para acessar o microfone.',
          text: 'Verifique se as conexões do navegador não está bloqueando o acesso ao microfone.',
          icon: 'warning',
        });
        return false;
      }
    }

    const { gravando } = gravarAudio;
    enviar = !!enviar;

    if (!gravando) {
      recorder.start();
    } else {
      if (recorder.activeStream) {
        recorder
          .stop()
          .getMp3()
          .then(async ([, blob]) => {
            if (enviar) {
              const base64 = await convertBlobToBase64(blob);
              await enviarArquivos([{ nomeArquivo: `Gravação de audio.mp3`, base64 }]);
            }
          });
      }
    }
    setGravarAudio({ gravando: !gravarAudio.gravando });
  };

  useEffect(() => {
    setFilter('');
    const divChat = document.getElementById('div-chat-principal');
    if (divChat) divChat.scrollTo(0, divChat.scrollHeight);
  }, [activeTab, chatAtivo]);

  const getTasksCalled = async id => {
    const {data} = await api.get(`/task/called/${id}`);
    setChat(chat => {
      if (chatAtivo && Number(chat[chatAtivo].id_chamado) === Number(id)) {
        chat[chatAtivo].tasksCalled = data;
      }
      return {...chat};
    });
    setTasksCalled(data);
  };

  useEffect(() => {
    getTasks().then(setTasks);
  }, []);
  
  useEffect(() => {
    setValueTabInfo(0);

    if (chatAtivo && !chatAtivo.toString().includes('g.us') && chat[chatAtivo].id_chamado) {
      getTasksCalled(chat[chatAtivo].id_chamado);
    }
    else setTasksCalled([]);
  }, [chatAtivo]);

  useEffect(() => {
    setTabEspera(0);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 0) {
      // const contatosFiltrados = filtrarArray(contatos).slice(0, 20);
      // setChatCarregados([...contatosFiltrados]);
    }

    if (activeTab === 2 || filter === '') {
      if (filter === '') setContactsPage(0);
      // if (!contatosCarregados.length) {
      //   const contatosFiltrados = filtrarArray(contatos).slice(0, 20);
      //   setContatosCarregados([...contatosFiltrados]);
      // } else {
      clearInterval(timeoutBuscaContatos);
      timeoutBuscaContatos = setTimeout(() => {
        // setContatos([]);
        getContacts(0, filter);
        // const contatosFiltrados = filtrarArray(contatos).slice(0, 20);
        // setContatosCarregados([...contatosFiltrados]);
      }, 700);
      // }
    }
  }, [filter]);

  useEffect(() => {
    if (webSocket) {
      webSocket.send(JSON.stringify({ metodo: 'adicionarEvento', evento: 'monitorarChat' }));
      webSocket.addEventListener('message', receberStatusWS);

      return () => {
        webSocket.send(JSON.stringify({ metodo: 'removerEvento', evento: 'monitorarChat' }));
        webSocket.removeEventListener('message', receberStatusWS);
      };
    }
  }, [webSocket, chat, chatCarregados]);

  const controlarFabScroll = () => {
    const componente = document.getElementById('div-chat-principal');
    if ((componente.scrollTop + componente.clientHeight !== componente.scrollHeight) !== showFabScroll)
      setShowFabScroll(!showFabScroll);
  };

  useEffect(() => {
    if (!!chatAtivo) {
      // #scroll #comentado
      /*document.getElementById('div-chat-principal').addEventListener('scroll', controlarFabScroll.bind(this));
      return () => {
        const div = document.getElementById('div-chat-principal');
        if (div)
          div.removeEventListener('scroll', controlarFabScroll.bind(this));
      };*/
    }
  }, [chatAtivo, showFabScroll]);

  const updateUltimaMensagemLida = async id_ultima_mensagem_lida => {
    if (!chatAtivo || !id_ultima_mensagem_lida) return;
    const update_message = {
      id_contato: chatAtivo,
      id_mensagem: id_ultima_mensagem_lida,
    };

    await WebService(`/chat/updateUltimaMensagemLida`, update_message, 'GET', false, undefinedFunction, false);
  };

  useEffect(() => {
    if (!chatAtivo) return () => '';
    const { mensagens, flag_atendimento } = chat[chatAtivo];
    if (!flag_atendimento) return () => '';

    if (mensagens.length && mensagens[mensagens.length - 1].id_mensagem !== chat[chatAtivo].id_ultima_mensagem_lida) {
      setChat(chat => {
        const { id_ultima_mensagem_lida } = chat[chatAtivo];
        chat[chatAtivo].id_ultima_mensagem_lida =
          chat[chatAtivo].mensagens[chat[chatAtivo].mensagens.length - 1].id_mensagem;
        if (chat[chatAtivo].id_ultima_mensagem_lida !== id_ultima_mensagem_lida)
          updateUltimaMensagemLida(chat[chatAtivo].id_ultima_mensagem_lida);
        return { ...chat };
      });
    }
  }, [chat]);

  useEffect(() => {
    if (!!chatAtivo) {
      setText('');
      setPage(0);
      setChat(chat => {
        const { mensagens, flag_atendimento, id_grupo_whatsapp } = chat[chatAtivo];
        if (!flag_atendimento && !id_grupo_whatsapp) return chat;
        const { id_ultima_mensagem_lida } = chat[chatAtivo];
        chat[chatAtivo].id_ultima_mensagem_lida = mensagens.length ? mensagens[mensagens.length - 1].id_mensagem : null;
        if (chat[chatAtivo].id_ultima_mensagem_lida !== id_ultima_mensagem_lida)
          updateUltimaMensagemLida(chat[chatAtivo].id_ultima_mensagem_lida);
        return { ...chat };
      });

      // - - -
      const messageCache = localStorage.getItem(`chat_message_cache:${chatAtivo}`);
      if (messageCache) {
        const { text } = JSON.parse(messageCache);
        setText(text);
      }
      // JSON.stringify({ text, date: new Date() }

      refInputTextChat.current.focus();
      refInputTextChat.current.select();
      // #scroll #comentado
      /*const componente = document.getElementById('div-chat-principal');
      componente.scrollTo(0, 90 * 90 * 90 * 90);*/
    }
  }, [chatAtivo]);

  const getChats = async () => {
    const { ok, chats, contatos, conexoes, usuarios, grupos } = await WebService(`/chat/getAllChats`);
    if (ok) {
      const chatCarregar = {};

      const chatsOrdem = getAllChatsOrdenado(chats);

      for (let i = 0; i <= 200; i++) {
        const key = chatsOrdem[i];
        chatCarregar[key] = chats[key];
      }

      // setContatos(contatos);
      setConexoes(conexoes);
      for (let i = 0; i < grupos.length; i += 1) {
        const grupo = grupos[i];
        grupo.id_whatsapp = grupo.id_grupo_whatsapp;
        grupo.id_whatsapp_conexao = grupo.id_whatsapp_conexao_fk;
        if (!grupo.mensagens) grupo.mensagens = [];
        grupo.mensagens = grupo.mensagens
          .sort((a, b) => {
            const { data: da } = a;
            const { data: db } = b;
            return new Date(da) > new Date(db) ? -1 : new Date(da) < new Date(db) ? 1 : 0;
          })
          .map(prop => {
            prop.mensagem = prop.body;
            prop.data = new Date(prop.data);
            if (typeof prop.localizacao === 'string') prop.localizacao = JSON.parse(prop.localizacao);
            return prop;
          });
        chats[grupo.id_grupo_whatsapp] = grupo;
      }

      setChat(chats);
      setGrupos(grupos);
      const sort = [...usuarios].sort((a, b) => a.nome.localeCompare(b.nome));
      setUsuarios(sort);

      const me = usuarios.find(el => el['me']);
      setMe(me);

      // setContatosCarregados(contatos.slice(0, 20));
      setChatCarregados(chatCarregar);
    }
  };

  const sendMessage = async (outros = {}) => {
    try {
      setSendingMessage(true);

      const { arquivo, texto: caption, id_mensagem_predefinida } = outros;
      const { id_whatsapp, uuidConexao, id_departamento_fk, id_departamento, id_chamado, id_whatsapp_conexao } = chat[
        chatAtivo
      ];

      const message = {
        type: 'text',
        text,
        caption,
        file: {},
        reply: undefined,
        connection: { id: parseFloat(id_whatsapp_conexao.toString()) },
        contact: { id: parseFloat(chatAtivo.toString()) },
        department: { id: parseFloat(id_departamento_fk || id_departamento) },
      };

      if (arquivo) {
        // Reliza o Upload do arquivo antes de enviar a mensagem
        const { nome, base64 } = arquivo;
        let messageKey;

        const mimeType = base64.split(';')[0].split(':')[1];
        const extension = nome.split('.').pop();

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase())) message.type = 'image';
        else if (['mp4'].includes(extension.toLowerCase())) message.type = 'video';
        else if (['mp3', 'ogg'].includes(extension.toLowerCase())) message.type = 'audio';
        else message.type = 'document';

        setChat(chat => {
          messageKey = chat[chatAtivo].mensagens.push({
            flag_enviado: true,
            data: new Date(),
            tipo: 'fileUpload',
            ack: 1,
            progress: 0,
            fileName: nome,
            mimeType,
          });
          return { ...chat };
        });
        const { uuid, filePath } = await uploadFile(nome, base64, progress => {
          setChat(chat => {
            chat[chatAtivo].mensagens[messageKey - 1].progress = progress;
            return { ...chat };
          });
        });
        message.file.uuid = uuid;
        message.file.filePath = filePath;

        if (!message.file.uuid) throw new Error('Erro ao enviar arquivo');

        message.file.extension = extension;
        setChat(chat => {
          delete chat[chatAtivo].mensagens[messageKey - 1];
          chat[chatAtivo].mensagens = chat[chatAtivo].mensagens.filter(el => el);
          return { ...chat };
        });
      }

      if (id_chamado) message.called = { id: parseFloat(id_chamado) };
      if (idMensagemResposta && idMensagemResposta.id)
        message.reply = { id: parseFloat(idMensagemResposta.id.toString()) };
      if (id_mensagem_predefinida)
        message.predefinedMessage = {
          id: parseFloat(id_mensagem_predefinida.toString()),
        };

      const { data } = await api.post('/message/send', message);

      setIdMensagemResposta({ id: 0, texto: null });
      let filePath = `midias/${message.file.uuid}.${message.file.extension}`;
      if (message.file.filePath) filePath = message.file.filePath;
      setChat(chat => {
        const mensagem = {
          id: data.id,
          flag_enviado: true,
          data: new Date(),
          tipo: data.type,
          mensagem: data.body,
          ack: 1,
          mediaURL: `https://storage.hustapp.com/${filePath}`,
          // FIX - Para a mídia funcionar precisa do [id_mensagem]
          usuario: {
            foto_perfil: data.user.profilePicture,
            nome: data.user.name,
          },
        };
        if (mensagem.tipo === 'text') mensagem.tipo = 'chat';

        if(!chat[chatAtivo].mensagens.map(prop => prop.id_message).includes(data.id))
          chat[chatAtivo].mensagens.push(mensagem);
        chat[chatAtivo].flag_atendimento = true;

        // FIX - Tratar id_chamado
        if (data.called) chat[chatAtivo].id_chamado = data.called.id;
        return { ...chat };
      });

      setText('');
      setActiveTab(0);
    } catch (error) {
      const {userMessage, userDescription} = (error && error.response && error.response.data) || {};
      Swal.fire({
        title: userMessage || 'Erro ao enviar mensagem',
        text: userDescription || 'Houve um erro ao enviar a mensagem, por favor tente em alguns instantes',
        icon: 'warning',
      });
    } finally {
      setSendingMessage(false);
    }
  };

  const enviarMensagem = async (outros = {}) => {
    if (!chatAtivo.toString().includes('g.us')) {
      await sendMessage(outros);
      return;
    }
    const tempChat = { ...chat };

    const id_contato = chatAtivo;
    const { id_whatsapp, uuidConexao, id_departamento_fk, id_departamento } = tempChat[chatAtivo];
    const params = {
      uuid: uuidConexao,
      id_mensagem_resposta: idMensagemResposta.id || null,
      numero: 'a',
      chatID: id_whatsapp,
      texto: text,
      id_contato,
      id_departamento_fk: id_departamento_fk || id_departamento,
      ...outros,
    };
    setIdMensagemResposta({ id: 0, texto: null });
    const posicao = tempChat[chatAtivo]['mensagens'].push({
      flag_enviado: true,
      data: new Date(),
      tipo: 'loading',
    });
    setChat(tempChat);

    const { ok, id_chamado, codigo_erro } = await WebService(`/whatsapp_api/enviarMensagem`, params, 'POST');
    if (ok) {
      setText('');
      tempChat[id_contato].flag_atendimento = true;
      if (id_chamado) tempChat[id_contato].id_chamado = id_chamado;
      // setChatCarregados({...chatCarregados, id_contato: tempChat[id_contato]});
    } else {
      if (codigo_erro === 54678) {
        const tempChat = { ...chat };
        const tempChatCarregados = { ...chatCarregados };
        delete tempChat[chatAtivo];
        delete tempChatCarregados[chatAtivo];
        setChatAtivo(0);
        setChat(tempChat);
        setChatCarregados(tempChatCarregados);
      }

      delete tempChat[id_contato].mensagens[posicao - 1];
      tempChat[id_contato].mensagens = tempChat[id_contato].mensagens.filter(el => el);
    }
    setChatCarregados(cc => {
      cc[id_contato] = tempChat[id_contato];
      // cc[id_contato].id_ultima_mensagem_lida = 9999999999;
      return cc;
    });

    if (!chatAtivo.toString().includes('g.us')) {
      setActiveTab(0);
    }

    setChat({ ...tempChat });
  };

  const colarArquivo = ({ clipboardData: { files } }) => carregarArquivoUpload(files);

  const getMensagensPredefinidas = async () => {
    const { ok, mensagensPredefinida } = await WebService(`/mensagem/getMensagemPredefinida`);
    if (ok) setMensagensPredefinidas(mensagensPredefinida);
  };

  const getContacts = async (page, filterLocal) => {
    const params = {
      q: filterLocal || filter || undefined,
      page: page || contactsPage,
      limit: 20,
    };
    const { data } = await api.get(`/contact`, { params });

    setContatos(contatos => {
      if (filterLocal || filter) contatos = [];

      contatos.push(
        ...data.results.map(prop => {
          return {
            foto_perfil: prop.profilePicture,
            id_contato: prop.id,
            id_whatsapp: prop.idWhatsapp,
            nome: prop.name,
          };
        }),
      );

      return [...contatos].filter(function(este, i) {
        return [...contatos].map(prop => prop.id_contato).indexOf(este.id_contato) === i;
      });
    });
  };

  const loadChat = async () => {
    await getChats();
    await getContacts();
    await getDepartamentos();
    await getMensagensPredefinidas();
  };

  useEffect(() => {
    document.addEventListener('paste', colarArquivo.bind(this));
    // window.addEventListener("resize", adaptarTamanhoDIV.bind(this));
    loadChat();

    return () => {
      document.removeEventListener('paste', colarArquivo.bind(this));
      // window.removeEventListener("resize", adaptarTamanhoDIV.bind(this));
    };
  }, []);

  const handleChangeMain = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeInfo = (event, newValue) => {
    setValueTabInfo(newValue);
  };

  const handleChangeTabEspera = (event, newValue) => {
    setValueTabEspera(newValue);
  };

  const handleCloseModalImagem = () => {
    setModalImagem({ open: false, url: null });
    setLoading(false);
  };

  const theme = useTheme();

  // const filterChat = (activeTab === 0 ? chat : contatos).filter(item => Object.keys(item).some(key => item[key].toString().toLowerCase().includes(filter.toLowerCase())));
  const handleSearch = ({ target: { value } }) => setFilter(value);
  const addEmoji = ({ native: emoji }) => setText(text + emoji);

  const carregarArquivoUpload = async arquivos => {
    const arquivosEnviar = [];
    for (let i = 0; i < arquivos.length; i++) {
      const reader = new FileReader();
      const newFile = arquivos[i];
      const { name: nomeArquivo } = newFile;
      if (newFile) {
        if (newFile.size > 100 * 1024 * 1000) {
          arquivosEnviar.push({
            nomeArquivo,
            flag_erro: true,
            mensagem_erro: 'O arquivo é maior que o limite de [100MB]',
            descricao: '',
          });
        } else {
          await new Promise(resolve => {
            reader.onloadend = resolve;
            reader.readAsDataURL(newFile);
          });
          arquivosEnviar.push({
            nomeArquivo,
            base64: reader.result,
            descricao: '',
          });
        }
      }
    }

    if (arquivosEnviar.filter(el => !el.flag_erro).length) {
      setArquivos(arquivosEnviar);
      setModalArquivosOpen(true);
    } else {
      if (arquivosEnviar[0])
        Swal.fire({
          title: 'Não foi possível carregar o arquivo',
          text: arquivosEnviar[0].mensagem_erro,
          icon: 'error',
        });
    }
  };

  const enviarArquivos = async (ta = arquivos) => {
    setModalCapturarOpen(false);
    setModalArquivosOpen(false);
    for (let i = 0; i < ta.length; i++) {
      const { nomeArquivo, base64, descricao } = ta[i];

      await enviarMensagem({
        texto: descricao || filesDescription.descriptions[i] || localStorage.getItem(`modal-arquivos-label-${i}`) || '',
        arquivo: { base64, nome: nomeArquivo },
      });
      localStorage.setItem(`modal-arquivos-label-${i}`, '');
    }
    setFilesDescription({ lastFocusKey: 0, descriptions: {} });
    setArquivos([]);
  };

  useEffect(() => {
    // window.jQuery('#dado-telefone').mask("(00) 0 0000-0000");
  }, [novoContato]);

  useEffect(() => {
    !sendingMessage && refInputTextChat?.current?.focus();
  }, [sendingMessage]);

  useEffect(() => {
    if (!modalArquivosOpen) {
      setArquivos([]);
      setFilesDescription({ lastFocusKey: 0, descriptions: {} });
    }
  }, [modalArquivosOpen]);

  const getDepartamentos = async () => {
    const { ok, departamentos, departamentosUsuario } = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      setDepartamentosUsuario(departamentosUsuario);
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const { nome, id_departamento, subDepartamentos } = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else
            tempDepartamentos.push({
              nome: `${n ? n + ' -> ' : ''}${nome}`,
              id_departamento,
            });
        });
      };
      getChild(departamentos);
      setDepartamentos(tempDepartamentos);
    }
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const tempArquivos = [...arquivos];
    tempArquivos.push({
      nomeArquivo: `Imagem ${arquivos.length + 1}`,
      categoria: 'image',
      base64: imageSrc,
      tipoArquivo: 'jpg',
    });
    setArquivos(tempArquivos);
  }, [webcamRef, arquivos]);

  const getAllChatsOrdenado = chats => {
    const chatLoad = chats || chat;
    return Object.keys(chatLoad)
      .map(prop => chatLoad[prop])
      .map(prop => prop.id_contato)
      .sort(function comparar(a, b) {
        const { dataUltimaMensagem: da } = chatLoad[a];
        const { dataUltimaMensagem: db } = chatLoad[b];
        return new Date(da) > new Date(db) ? -1 : new Date(da) < new Date(db) ? 1 : 0;
      });
  };

  const carregarMaisContatos = () => {
    if (activeTab === 0) {
      const chatCarregar = {};
      const inicial = Object.keys(chatCarregados).length;

      const chatsOrdem = getAllChatsOrdenado();

      for (let i = inicial; i <= inicial + 20; i++) {
        const key = chatsOrdem[i];
        chatCarregar[key] = chat[key];
      }

      setChatCarregados({ ...chatCarregados, ...chatCarregar });
    } else if (activeTab === 2) {
      setContactsPage(cp => {
        getContacts(cp + 1);
        return cp + 1;
      });

      // const tempContatosCarregados = [...contatosCarregados, ...contatos.slice(contatosCarregados.length, contatosCarregados.length + 15)];
      // setContatosCarregados(tempContatosCarregados);
    }
  };

  const carregarAnteriores = async (id_mensagem_resposta, pageNew, chatLoad = []) => {
    // Caso a mensagem citada já exista, ignora o carregamento
    if (id_mensagem_resposta && chat[chatAtivo].mensagens.map(prop => prop.id_mensagem).includes(id_mensagem_resposta))
      return;

    const {ok, mensagens} = await WebService(`/chat/getMensagensChat`, {
      id_chat: chatAtivo,
      page: pageNew || page
    });

    if (ok) {
      const tempChat = { ...chat };
      const allMessages = [...mensagens, ...tempChat[chatAtivo].mensagens, ...chatLoad];
      tempChat[chatAtivo].mensagens = [];
      for (let i = 0; i < allMessages.length; i += 1) {
        if (!tempChat[chatAtivo].mensagens.map(prop => prop.id_mensagem).includes(allMessages[i].id_mensagem))
          tempChat[chatAtivo].mensagens.push(allMessages[i]);
      }
      tempChat[chatAtivo].carregadoQtd += mensagens.length;
      setChat(tempChat);
      setPage((pageNew || page) + 1);
      if (!page) {
        const div = document.getElementById('div-chat-principal');
        if (div) div.scrollTo(0, 90 * 90 * 90 * 90);
      }

      if (id_mensagem_resposta) {
        if (!chat[chatAtivo].mensagens.map(prop => prop.id_mensagem).includes(id_mensagem_resposta)) {
          await carregarAnteriores(id_mensagem_resposta, (pageNew || page) + 1, tempChat[chatAtivo].mensagens);
        }
      }
    }
  };

  const adicionarNovoContato = async cadastrar => {
    if (cadastrar) {
      const { telefone, nome, fotoPerfil, id_contato, id_whatsapp } = novoContato;
      const { ok } = await WebService(`/contato/cadastrarContato`, { id_contato, nome }, 'POST');
      if (ok) {
        await Swal.fire({
          icon: 'success',
          title: 'Usuário cadastrado com sucesso!',
        });
        setModalAdicionarContato(false);
        const tempChat = { ...chat };
        // *******************************************
        let { uuid: conexaoEnvio, id_whatsapp_conexao: conexaoEnvioID } = conexoes[0];

        if (conexoes.length > 1) {
          const conexoesTemp = {};
          const conexoesTempId = {};
          for (let i = 0; i < conexoes.length; i++) {
            const { uuid, conta_nome } = conexoes[i];
            conexoesTemp[uuid] = conta_nome;
          }
          const { value: conexao } = await Swal.fire({
            title: 'Escolha a conexão para realizar o envio',
            input: 'select',
            inputOptions: conexoesTemp,
            inputPlaceholder: 'Selecione a conexão',
            showCancelButton: false,
            allowOutsideClick: false,
            inputValidator: value => (value ? '' : 'Selecione uma conexão para enviar a mensagem'),
          });
          if (!conexao) return false;
          conexaoEnvio = conexao;
          conexaoEnvioID = conexoes.find(el => el.uuid === conexao).id_whatsapp_conexao;
        }

        // *******************************************
        let [departamentoEnvio] = departamentosUsuario;
        if (departamentosUsuario.length > 1) {
          const departamentosKey = {};
          departamentos.map(prop => {
            const { id_departamento, nome } = prop;
            if (departamentosUsuario.indexOf(id_departamento) > -1) departamentosKey[id_departamento] = nome;
          });
          const { value: departamentoSelecionado } = await Swal.fire({
            title: 'Selecione o departamento para envio',
            input: 'select',
            inputOptions: departamentosKey,
            inputPlaceholder: 'Selecione o departamento',
            showCancelButton: true,
            inputValidator: value => (value ? '' : 'Selecione um departamento válido'),
          });

          if (!departamentoSelecionado) return false;
          departamentoEnvio = departamentoSelecionado;
        }

        if (!tempChat[id_contato]) {
          tempChat[id_contato] = {
            uuidConexao: conexaoEnvio,
            id_contato,
            id_whatsapp,
            nome: nome || telefone,
            foto_perfil: fotoPerfil,
            dataUltimaMensagem: new Date(),
            mensageiro: 'whatsapp',
            id_departamento_fk: departamentoEnvio,
            id_whatsapp_conexao: conexaoEnvioID,
            mensagens: [],
          };
          chatCarregados[id_contato] = tempChat[id_contato];
          setChat(tempChat);
          setChatCarregados({ ...chatCarregados });
        }
        setActiveTab(1);
        setChatAtivo(parseInt(id_contato));
      }
    } else {
      setNovoContato({
        ...novoContato,
        nome: '',
        fotoPerfil: '',
        id_contato: '',
        id_whatsapp: '',
      });
      const { ok, contato } = await WebService(`/contato/getContatoInfo`, {
        numero: novoContato.telefone,
      });
      if (ok) {
        const { foto_perfil, id_whatsapp, id_contato, nome } = contato;
        const contatoNovo = {
          ...novoContato,
          id_whatsapp,
          fotoPerfil: foto_perfil,
          id_contato,
          nome,
          telefone: id_whatsapp.split('@')[0],
        };

        setNovoContato(contatoNovo);
        Object.keys(contatoNovo).map(prop => (novoContato[prop] = contatoNovo[prop]));
        return novoContato;
      }
    }
  };

  useEffect(() => {
    if (!modalAdicionarContato)
      setNovoContato({
        telefone: '',
        nome: '',
        fotoPerfil: '',
        id_contato: '',
        id_whatsapp: '',
      });
  }, [modalAdicionarContato]);

  const getDepartamentoLabelFromId = id_departamento => {
    const departamentosId = {};
    departamentos.map(prop => {
      const { nome, id_departamento } = prop;
      departamentosId[id_departamento] = nome;
    });
    return departamentosId[id_departamento];
  };

  const getOpcoesMenuChat = (id_mensagem, caption, mensagem) => (
    <IconButton
      id="mais-opcoes-mensagem"
      onClick={e =>
        setPopOpcoesMensagem({
          open: true,
          ref: e.currentTarget,
          id_mensagem,
          mensagem: caption || mensagem,
        })
      }
      style={{ color: primaryColor[0], backgroundColor: '#FFF' }}
    >
      <ExpandMore />
    </IconButton>
  );

  const getConexaoNome = conexaoT => {
    const { id_whatsapp_conexao } = conexaoT;
    const conexao = conexoes.find(el => el.id_whatsapp_conexao === id_whatsapp_conexao);
    if (conexao) return conexao['conta_nome'];
    return '';
  };

  const abrirChatDireto = async (telefone, wid, nome) => {
    novoContato.telefone = telefone;
    setNovoContato(nc => ({ ...nc, telefone }));
    const contato = await adicionarNovoContato(false);

    if (contato.id_whatsapp) {
      if (contato.nome) {
        setNovoContato(nc => ({ ...nc, nome }));
        novoContato.nome = nome;
      }
      await adicionarNovoContato(true);
    }
  };

  const DivArquivos = () => (
    <GridContainer style={{ display: 'flex' }}>
      {arquivos.map((prop, key) => {
        const { nomeArquivo, base64, descricao } = prop;
        let categoria, tipoArquivo;
        if (base64) {
          const matches = base64.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
          if (matches && matches.length) {
            const [, tipo, conteudo] = matches;
            const [c, extensao] = tipo.split('/');
            categoria = c;
            tipoArquivo = extensao;
          }
        }
        return (
          <GridItem
            style={{ display: 'inline-flex' }}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            key={key}
            // style={{backgroundColor: '#DDD', borderRadius: 5, marginTop: 10}}
          >
            <Card
              style={{
                backgroundColor: '#EEE',
                borderRadius: 5,
                marginTop: 10,
              }}
            >
              <CardBody>
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <p>{nomeArquivo}</p>
                  </GridItem>
                  {categoria === 'image' && (
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <img
                        src={base64}
                        alt={nomeArquivo}
                        style={{
                          height: '100%',
                          maxHeight: 300,
                          maxWidth: '100%',
                        }}
                      />
                    </GridItem>
                  )}
                  {tipoArquivo === 'pdf' && (
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <Document file={base64} align="center">
                        <Page pageNumber={1} height={300} />
                      </Document>
                    </GridItem>
                  )}
                  {['png', 'jpg', 'jpeg', 'gif', 'mp4'].indexOf(tipoArquivo) > -1 && (
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <CustomInput
                        inputProps={{
                          placeholder: 'Descrição',
                          // value: filesDescription.descriptions[key] || '',
                          defaultValue: localStorage.getItem(`modal-arquivos-label-${key}`) || '',
                          // onFocus: () => filesDescription.lastFocusKey = key,
                          onChange: ({ target: { value } }) => {
                            localStorage.setItem(`modal-arquivos-label-${key}`, value);
                            // setFilesDescription(fd => {
                            //   fd.descriptions[key] = value;
                            //   return {...fd};
                            // });
                          },
                        }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
  );

  const refAudioNotification = useRef();
  const gridChatsRef = useRef(null);
  const [gridChatsWidth, setGridChatsWidth] = useState(0);

  const [taskID, setTaskID] = useState(null);

  // - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - *
  useEffect(() => {
    const handleResize = () => {
      if (gridChatsRef.current) setGridChatsWidth(gridChatsRef.current.offsetWidth);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (gridChatsRef.current) resizeObserver.observe(gridChatsRef.current);

    // Cleanup observer on component unmount
    return () => {
      if (resizeObserver && gridChatsRef.current) resizeObserver.unobserve(gridChatsRef.current);
    };
  }, []);
  // - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - * - *
  return (
    <div className={classes.root}>
      <ModalUploadMediaFiles
        open={arquivos.length}
        filesInit={arquivos}
        isMobile={isMobile}
        onClose={files => {
          if (files) {
            enviarArquivos(
              files.map(file => ({
                nomeArquivo: file.name,
                base64: file.src,
                descricao: file.description,
              })),
            );
          }
          setArquivos([]);
        }}
      />
      <ModalUploadMediaCamera
        open={modalCapturarOpen}
        onClose={() => setModalCapturarOpen(false)}
        onUpload={files => setArquivos(files)}
      />
      <ModalEndCalled
        open={openModalEndCalled}
        onClose={() => setOpenModalEndCalled(false)}
        onCalledEnd={finalizarAtendimento}
        id={chatAtivo && chat[chatAtivo].id_chamado}
      />
      <ModalScheduledMessages
        open={openModalScheduledMessages}
        onClose={() => setOpenModalScheduledMessages(false)}
        idContact={chatAtivo}
        contactName={chatAtivo ? chat[chatAtivo].nome : ''}
        idConnection={chatAtivo ? chat[chatAtivo].id_whatsapp_conexao : 0}
      />
      <TaskViewer open={!!taskID} id={taskID} onClose={() => setTaskID(null)} />
      <TextSuggestion
        open={textSugestionAI}
        text={textSugestionAI}
        onClose={text => {
          setTextSugestionAI('');
          if (text) setText(text);
        }}
      />
      <audio style={{ display: 'none' }} ref={refAudioNotification} preload>
        <source src={audioNotification} type={'audio/mp3'} />
      </audio>
      <CssBaseline />
      {loading && (
        <Modal
          open={loading}
          onClose={() => handleCloseModalImagem()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <>
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleCloseModalImagem()}>
              <Close color="error" />
            </IconButton>
            <Fade in={loading}>
              <CircularProgress style={{ color: '#fff' }} />
            </Fade>
          </>
        </Modal>
      )}
      {modalImagem.open && (
        <Lightbox
          medium={modalImagem.url}
          large={modalImagem.url}
          showRotate
          onClose={() => setModalImagem({ open: false, url: '' })}
        />
      )}
      {/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
      <Modal open={modalAdicionarContato} onClose={() => setModalAdicionarContato(false)} className={classes.modal}>
        <>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setModalAdicionarContato(false)}
          >
            <Close />
          </IconButton>
          <Card className={classes.cardUser}>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <PersonAdd />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Adicionar Contato</h4>
            </CardHeader>
            <form>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <p className={classes.cardIconSubtitle}>
                      <Info /> Insira o número do contato e clique em <strong> Buscar</strong>
                    </p>
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Telefone"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        value: novoContato.telefone,
                        onChange: ({ target: { value } }) => {
                          setNovoContato({
                            telefone: value,
                            nome: '',
                            fotoPerfil: '',
                            id_contato: '',
                            id_whatsapp: '',
                          });
                        },
                        id: 'dado-telefone',
                      }}
                    />
                    <CustomInput
                      labelText="Nome"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: !novoContato.id_whatsapp,
                        value: novoContato.nome,
                        onChange: ({ target: { value } }) => setNovoContato({ ...novoContato, nome: value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.imageUpload}>
                    <center>
                      <img
                        alt={'foto-perfil'}
                        src={getFotoLink(novoContato.fotoPerfil)}
                        style={{
                          height: 100,
                          widt: 100,
                          transition: '.1s',
                          borderRadius: 15,
                          // border: `2px solid ${infoColor[0]}`,
                          // opacity: !!novoContato.id_whatsapp ? 1 : 0
                        }}
                      />
                    </center>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter className={classes.cardFooter} stats>
                {novoContato.id_whatsapp ? (
                  <CustomButtons
                    round
                    onClick={() =>
                      setNovoContato({
                        telefone: '',
                        nome: '',
                        fotoPerfil: '',
                        id_contato: '',
                        id_whatsapp: '',
                      })
                    }
                  >
                    Limpar
                  </CustomButtons>
                ) : (
                  <CustomButtons round onClick={() => setModalAdicionarContato(false)}>Cancelar</CustomButtons>
                )}
                {novoContato.id_whatsapp ? (
                  <CustomButtons color="hust" round onClick={() => adicionarNovoContato(true)}>
                    Adicionar
                  </CustomButtons>
                ) : (
                  <CustomButtons color="warning" round onClick={() => adicionarNovoContato(false)}>
                    Buscar
                  </CustomButtons>
                )}
              </CardFooter>
            </form>
          </Card>
        </>
      </Modal>
      {/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
      <Modal
        onClose={() =>
          setModalTransferirChamado({
            id_chamado: '',
            id_departamento: '',
            id_usuario: '',
          })
        }
        open={!!modalTransferirChamado.id_chamado}
        className={classes.modal}
      >
        <>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() =>
              setModalTransferirChamado({
                id_chamado: '',
                id_departamento: '',
                id_usuario: '',
              })
            }
          >
            <Close />
          </IconButton>
          <Card className={classes.cardUser}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <SyncAlt />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Transferir Chamado</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Departamento</InputLabel>
                    <Select
                      value={modalTransferirChamado.id_departamento}
                      onChange={({ target: { value } }) =>
                        setModalTransferirChamado({
                          ...modalTransferirChamado,
                          id_departamento: value,
                        })
                      }
                      style={{ textAlign: 'left' }}
                    >
                      {departamentos.map(prop => {
                        const { nome, id_departamento } = prop;
                        return (
                          <MenuItem value={id_departamento} key={`dpt_${id_departamento}`}>
                            {nome}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <p>&nbsp;</p>
                {!!modalTransferirChamado.id_departamento && (
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel>Usuário</InputLabel>
                      <Select
                        value={modalTransferirChamado.id_usuario}
                        onChange={({ target: { value } }) =>
                          setModalTransferirChamado({
                            ...modalTransferirChamado,
                            id_usuario: value,
                          })
                        }
                        style={{ textAlign: 'left' }}
                      >
                        {usuarios
                          .filter(
                            el =>
                              el.id_departamento_fk &&
                              !el.me &&
                              el.id_departamento_fk
                                .split(',')
                                .map(prop => parseInt(prop))
                                .indexOf(modalTransferirChamado.id_departamento) > -1,
                          )
                          .map(prop => {
                            const { nome, id_usuario } = prop;
                            const sort = [...usuarios].sort((a, b) => a.nome.localeCompare(b.nome));

                            return (
                              <MenuItem value={id_usuario} key={`usr_${id_usuario}`}>
                                {nome}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.cardFooter} stats>
              <CustomButtons
                onClick={() =>
                  setModalTransferirChamado({
                    id_chamado: '',
                    id_departamento: '',
                    id_usuario: '',
                  })
                }
              >
                Cancelar
              </CustomButtons>
              <CustomButtons
                color="warning"
                onClick={transferirAtendimento.bind(this)}
                disabled={!modalTransferirChamado.id_departamento}
              >
                Transferir
              </CustomButtons>
            </CardFooter>
          </Card>
        </>
      </Modal>
      {/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
      <Dialog
          open={openModalIndicacao}
          onClose={() => setOpenModalIndicacao(false)}
          fullWidth
      >
        <DialogTitle style={{textAlign: 'center'}}>
          Indique e ganhe no PIX!
        </DialogTitle>
        {modalIndicacaoTab === 0 ? (
            <DialogContent>
              <div style={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
                <img src={IllustrationPigMoney} alt={'illustration-sent'}
                     style={{width: 'fit-content', height: 120, marginTop: -20}}/>
                <Typography style={{textAlign: 'center'}}>
                  Cada cliente que você indicar que contratar a plataforma, <p style={{margin: 0}}/>
                  você recebe <strong style={{color: primaryColor[0]}}>30% de comissão</strong> da contratação!<br/>
                </Typography>
                <Typography style={{textAlign: 'center'}}>
                  Além disso, quem seguir sua indicação ganha <p style={{margin: 0}}/>
                  <strong style={{color: primaryColor[0]}}>10% de desconto</strong> na contratação!
                </Typography>
              </div>
            </DialogContent>
        ) : ''}
        {modalIndicacaoTab === 1 ? (
            <DialogContent>
              <GridContainer justifyContent={'center'}>
                <GridItem lg={12} md={12} sm={12} xs={12} style={{marginBottom: 15, marginTop: -12}} align={'center'}>
                  <Button
                    style={{padding: '5px 15px'}}
                    color={'hust'}
                    onClick={async () => {
                      setOpenModalIndicacao(false);
                      const {value} = await Swal.fire({
                        icon: "question",
                        title: 'Qual seu Whatsapp para te enviarmos o material?',
                        input: 'tel',
                        confirmButtonText: 'Receber material',
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar'
                      });
                      setOpenModalIndicacao(true);
                      setModalIndicacaoTab(1);
                      if (!value) return Swal.fire({
                        icon: "warning",
                        title: 'O número de telefone informado não é válido.',
                        text: 'Insira um número de whatsapp válido e tente novamente!'
                      });
                      const {ok} = await WebService('/usuario/enviarMaterialIndicacao', {phone: value});
                      if (!ok) return;
                      await Swal.fire({
                        icon: "success",
                        title: 'Te enviamos uma mensagem!',
                        text: 'Em breve você receberá seu material para realizar suas indicações!'
                      });
                    }}
                  >
                    Receber no whatsapp
                  </Button>
                </GridItem>
                {['001', '002', '003', '004', '005'].map(prop => {
                  const url = `${apiURL}/usuario/getCupomIndicacao?image=${prop}&token=${ls('token')}`;
                  return (
                      <GridItem lg={2} md={2} sm={2} xs={4}>
                        <img src={url} style={{width: '100%'}} alt={prop} />
                        <Button color={'info'} round fullWidth style={{padding: 5}} onClick={async ()=> {
                          downloadFileAsBlob(await fetchAsBlob(url), `Cupom Inidicação Hust - ${prop}.jpg`);
                        }}>
                          baixar
                        </Button>
                      </GridItem>
                  );
                })}
              </GridContainer>
            </DialogContent>
        ) : ''}
        {modalIndicacaoTab === 2 ? (
          <DialogContent>
            <GridContainer justifyContent={'center'}>
              <GridItem lg={6} md={6} sm={6} xs={12} style={{ position: 'relative', justifyContent: 'center' }}>
                <img src={rankingImage} alt={'ranking'} style={{width: 250}}/>
                {[
                    ...indicacaoRanking.filter(el => el.count),
                    ...(new Array(3).fill(null).map(() => ({profilePicture: '', name: ''})))
                ].slice(0, 3).map((prop, key) => (
                  <Tooltip title={prop.name}>
                    <img
                      src={`https://storage.hustapp.com/foto_perfil/${prop.profilePicture || 'Fotw0xW5Z0KKokGp7Jyt7SwKAoVgZvpiy7c1ZGdRKtHcnwqnSu.png'}`}
                      alt={prop.name}
                      style={{
                        width: key === 0 ? 47 : 40,
                        borderRadius: '50%', ...primaryBoxShadow,
                        top: key === 0 ? 20 : 40,
                        left: key === 0 ? 118 : (key === 1 ? 59 : 179),
                        position: 'absolute'
                      }}
                    />
                  </Tooltip>
                ))}
              </GridItem>
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <table
                  style={{
                    width: '100%',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 300,
                    lineHeight: 1.428571,
                    fontSize: '1rem',
                    borderSpacing: 0
                  }}
                >
                  {indicacaoRanking.map((prop, key) => {
                    const keys = {0: medal1st,1: medal2st,2: medal3st}
                    return (
                        <tr key={prop.id}>
                          <td style={{borderTop: '1px solid #DDD'}}>
                            {prop.count && keys[key] ? (<img style={{width: 25}} src={keys[key]} alt={'1st'}/>) : ''}
                          </td>
                          <td style={{borderTop: '1px solid #DDD'}}>
                            <img
                              alt={prop.name}
                              src={`https://storage.hustapp.com/foto_perfil/${prop.profilePicture || 'Fotw0xW5Z0KKokGp7Jyt7SwKAoVgZvpiy7c1ZGdRKtHcnwqnSu.png'}`}
                              style={{width: 20, borderRadius: '50%', marginRight: 3}}
                            />
                            {prop.name}
                          </td>
                          <td style={{borderTop: '1px solid #DDD'}}>{prop.count}&nbsp;<small style={{fontSize: 9}}>Indicações</small></td>
                        </tr>
                    );
                  })}
                </table>
              </GridItem>
            </GridContainer>
          </DialogContent>
        ) : ''}
        <DialogActions style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Button color={modalIndicacaoTab === 0 ? 'hust' : 'github'} round onClick={() => setModalIndicacaoTab(0)}>
            Indicação
          </Button>
          <Button color={modalIndicacaoTab === 1 ? 'hust' : 'github'} round onClick={() => setModalIndicacaoTab(1)}>
            Meus cupons
          </Button>
          <Button color={modalIndicacaoTab === 2 ? 'hust' : 'github'} round onClick={() => setModalIndicacaoTab(2)}>
            Ranking
          </Button>
        </DialogActions>
      </Dialog>
      {/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
      <main
        className={clsx(classes.content, { [classes.contentShift]: open || isMobile })}
        style={{marginRight: (chatAtivo && !open && !isMobile ? -drawerWidth : 0) - 15}}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className={classes.root}
          spacing={0}
          style={{ fontSize: '12px' }}
        >
          {!(chatAtivo && ['sm', 'xs'].indexOf(width) > -1) && (
            <Grid item xs={width === 'lg' ? 4 : width === 'md' ? 4 : width === 'xs' ? 12 : width === 'xl' && 3} ref={gridChatsRef}>
              <Paper className={classes.main}>
                <div className={classes.headerMain}>
                  <Grid container direction="row" alignItems="flex-start" className={classes.root} spacing={0}>
                    <Grid item xs={8}>
                      <h4 className={classes.titleLogo}>{nomeAplicacao}</h4>
                    </Grid>

                    {!isPartner && (
                      <Grid item xs={2} style={{ maxHeight: 40 }}>
                        <Tooltip title={'Indique e Ganhe'}>
                          <Button simple round justIcon style={{ padding: 0 }} onClick={() => setOpenModalIndicacao(true)}>
                            <GroupAdd style={{ fontSize: 20, width: 25, height: 25 }} />
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <Tooltip title={me.nome}>
                        <img
                          ref={ref => (refImagemAtendente = ref)}
                          alt={me.nome}
                          src={getFotoLink(me.foto_perfil)}
                          className={classes.chatProfilePictureAtendente}
                          onClick={({ currentTarget }) =>
                            setOpcoesMenuAtendente({
                              ref: currentTarget,
                              open: true,
                            })
                          }
                        />
                      </Tooltip>
                    </Grid>
                    <Popover
                      open={opcoesMenuAtendente.open}
                      anchorEl={refImagemAtendente}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <ClickAwayListener onClickAway={() => setOpcoesMenuAtendente({ open: false, ref: null })}>
                        <List component="nav">
                          <ListItem button onClick={() => alterarSenhaUsuario()}>
                            <ListItemIcon>
                              <LockOutlined />
                            </ListItemIcon>
                            <ListItemText primary={'Alterar Senha'} />
                          </ListItem>
                          <ListItem button onClick={() => history.push(`/sair`)}>
                            <ListItemIcon>
                              <ExitToApp />
                            </ListItemIcon>
                            <ListItemText primary={'Sair'} />
                          </ListItem>
                        </List>
                      </ClickAwayListener>
                    </Popover>
                    <Grid item xs={12} style={{ display: 'flex', paddingRight: 10 }}>
                      <Paper component="form" className={classes.formSearch} style={{width: 'calc(100% - 70px)'}}>
                        <InputBase
                          className={classes.inputSearch}
                          placeholder="Buscar por mensagem ou contato"
                          //style={{fontSize: '14px'}}
                          inputProps={{
                            'aria-label': 'search message or contact',
                          }}
                          value={filter}
                          onChange={({ target: { value } }) => setFilter(value)}
                        />
                        <Search className={classes.iconColorPrimary} />
                      </Paper>
                      <Tooltip title={'Novo contato'}>
                        <IconButton
                          type="button"
                          style={{
                            color: primaryColor[0],
                            background: '#FFF',
                            width: 40,
                            height: 40,
                            marginTop: 10,
                            borderRadius: '0 17px 0 0'
                          }}
                          className={classes.iconButton}
                          aria-label="novo-contato"
                          onClick={() => setModalAdicionarContato(true)}
                        >
                          <PersonAdd />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        margin: 0,
                        padding: '0 !important',
                        fontSize: '12px',
                      }}
                    >
                      <StyledTabs value={activeTab} onChange={(a, b) => setActiveTab(b)} variant="fullWidth">
                        {[
                          {
                            label: 'Conversas',
                            active: true,
                            //icon: Chat,
                            badge: {
                              color: infoColor[0],
                              value: Object.keys(chat)
                                .map(prop => chat[prop])
                                .filter(el => !el.id_whatsapp.includes('g.us'))
                                .map(prop => prop['flag_atendimento'])
                                .filter(el => el).length,
                            },
                          },
                          {
                            label: 'Espera',
                            active: true,
                            //icon: HourglassEmpty,
                            badge: {
                              color: warningColor[0],
                              value: Object.keys(chat)
                                .map(prop => chat[prop])
                                .filter(el => !el.id_whatsapp.includes('g.us'))
                                .map(prop => prop['flag_atendimento'])
                                .filter(el => !el).length,
                            },
                          },
                          {
                            label: 'Contatos',
                            //icon: Person,
                            active: true,
                            badge: null,
                          },
                          // {
                          //   label: "Chat",
                          //   //icon: Person,
                          //   badge: HourglassEmpty
                          // },
                          {
                            label: 'Grupos',
                            active: true,
                            badge: {
                              color: primaryColor[0],
                              value: grupos.filter(el => {
                                const { id_department_fk, id_user_fk } = el;
                                const { id_departamento_fk: departamentos, id_usuario } = usuarios.find(
                                    el => el.id_usuario === me.id_usuario,
                                )||{};
                                const flag_gestor = JSON.parse(localStorage.getItem('usuario_gestor') || 'false');
                                if (flag_gestor) return true;

                                if (id_department_fk) {
                                  const permission = (departamentos || '')
                                      .split(',')
                                      .map(prop => parseFloat(prop))
                                      .includes(parseFloat(id_department_fk));
                                  if (!permission) return false;
                                }
                                if (id_user_fk) return parseFloat(id_user_fk) === parseFloat(id_usuario);
                                return true;
                              }).filter(el => {
                                const { mensagens, id_ultima_mensagem_lida, config } = el;
                                let mensagensNaoLidas = 0;
                                if (id_ultima_mensagem_lida) {
                                  const mensagensOrdemID = mensagens.sort((a, b) =>
                                      a.id_mensagem < b.id_mensagem ? -1 : b.id_mensagem > a.id_mensagem ? 1 : 0,
                                  );
                                  mensagensNaoLidas = mensagensOrdemID
                                      .slice(
                                          mensagensOrdemID.map(prop => prop.id_mensagem).indexOf(id_ultima_mensagem_lida) +
                                          1 || -1,
                                      )
                                      .filter(el => {
                                        const {usuario} = el;
                                        if (!config || config.type !== 'private' || !usuario) return !el.flag_enviado;
                                        return (usuario.id_usuario !== me.id_usuario);
                                      }).length;
                                }
                                if (mensagens.length && !id_ultima_mensagem_lida) mensagensNaoLidas = mensagens.length;
                                return mensagensNaoLidas > 0;
                              }).length,
                            },
                          },
                        ]
                          .filter(el => el.active)
                          .map((prop, key) => {
                            const {
                              label,
                              //icon: Icon,
                              badge,
                            } = prop;
                            let BadgeComponent;
                            if (badge) {
                              const { color, value } = badge;
                              if (value) {
                                BadgeComponent = (
                                  <div
                                    style={{
                                      //marginLeft: '20px',
                                      //marginTop: '16px'
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: '11px',
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      {label} &nbsp;
                                    </p>
                                    <span
                                      style={{
                                        backgroundColor: '#f5f5f5',
                                        color: primaryColor[0],
                                        width: 15,
                                        height: 15,
                                        borderRadius: '50%',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {value}
                                    </span>
                                  </div>
                                );
                              }
                            }
                            return (
                              <StyledTab
                                key={`tab_${key}`}
                                label={BadgeComponent || label}
                                //icon={<Icon/>}
                              />
                            );
                          })}
                      </StyledTabs>
                    </Grid>
                  </Grid>
                </div>
                {/******************************************************************************************************/}
                <Card
                  plain
                  className={classes.chatCards}
                  style={{
                    // height: `${activeTab}` >= 1 && 'calc(100vh - 215px)', // Não tenho ideia do pq isso foi feito
                    height: `calc(100vh - 147px)`,
                  }}
                  onScroll={({ target }) => {
                    const { offsetHeight, scrollHeight, scrollTop } = target;
                    const percentScrool = (scrollTop / (scrollHeight - offsetHeight)) * 100;

                    if (percentScrool > 90) setLoadedChat(loadedChat + 1);
                  }}
                >
                  <CardBody plain>
                    {[0, 1].indexOf(activeTab) > -1 ? (
                      <FlipMove
                          duration={300}
                          easing="ease-out"
                          enterAnimation="fade"
                          leaveAnimation={null}
                          typeName={null}
                      >
                        {filtrarArray(Object.keys(chat).map(prop => chat[prop]))
                          .filter(el => activeTab === 1 ? (tabEspera === 0 ? !el.botsData?.runningBOT : el.botsData?.runningBOT) : true)
                          .filter(el => !el.id_whatsapp.includes('g.us'))
                          .filter(el => (activeTab ? !el.flag_atendimento : el.flag_atendimento))
                          .map(prop => {
                            prop.mensagens = prop.mensagens.sort((a, b) => {
                              const { data: da } = a;
                              const { data: db } = b;
                              return new Date(da) > new Date(db) ? 1 : new Date(da) < new Date(db) ? -1 : 0;
                            });

                            return prop;
                          })
                          .sort((a, b) => {
                            const [da] = a.mensagens.slice(-1);
                            const [db] = b.mensagens.slice(-1);

                            let { data: dataA } = da || {};
                            let { data: dataB } = db || {};

                            dataA = dataA || new Date(a.data_chamado);
                            dataB = dataB || new Date(b.data_chamado);

                            return new Date(dataA) > new Date(dataB) ? -1 : new Date(dataA) < new Date(dataB) ? 1 : 0;
                          })
                          .slice(0, (loadedChat + 1) * 30)
                          .map(prop => {
                            let {
                              nome,
                              foto_perfil,
                              mensageiro,
                              mensagens,
                              id_whatsapp,
                              id_ultima_mensagem_lida,
                              id_contato,
                              data_chamado,
                              botsData,
                              tasksCalled
                            } = prop;
                            let ultimaMensagem = 'Nenhuma mensagem';
                            let dataUltimaMensagem = new Date(data_chamado);
                            let enviado = false;
                            let mensagensNaoLidas = 0;
                            let flagExcluida = false;
                            mensagens = mensagens.filter(el => el.tipo !== 'sistema');
                            if (mensagens && mensagens.length) {
                              const mensagensOrdenada = mensagens.sort((a, b) => {
                                const { data: da } = a;
                                const { data: db } = b;
                                return new Date(da) > new Date(db) ? 1 : new Date(da) < new Date(db) ? -1 : 0;
                              });
                              const { mensagem, data, tipo, flag_enviado, flag_excluida } = mensagensOrdenada[
                                mensagensOrdenada.length - 1
                              ];
                              flagExcluida = flag_excluida;
                              dataUltimaMensagem = data;
                              enviado = flag_enviado;
                              ultimaMensagem = tipo === 'chat' ? mensagem : chatTiposTraducao[tipo] || tipo;
                              if (id_ultima_mensagem_lida) {
                                const mensagensOrdemID = mensagens.sort((a, b) =>
                                  a.id_mensagem > b.id_mensagem ? 1 : b.id_mensagem < a.id_mensagem ? -1 : 0,
                                );
                                mensagensNaoLidas = mensagensOrdemID
                                  .slice(
                                    mensagensOrdemID.map(prop => prop.id_mensagem).indexOf(id_ultima_mensagem_lida) +
                                      1 || -1,
                                  )
                                  .filter(el => !el.flag_enviado).length;
                              }
                            }
                            return (
                              <FunctionalArticle
                                key={id_contato}
                                enviado={enviado}
                                registroDiaHora={dataUltimaMensagem}
                                nome={nome || formataNumeroTelefone(id_whatsapp.split('@')[0])}
                                ultimaMensagem={ultimaMensagem}
                                fotoPerfil={getFotoLink(foto_perfil)}
                                mensageiro={mensageiro || 'whatsapp'}
                                ativo={id_contato === chatAtivo}
                                onClick={() => setChatAtivo(id_contato)}
                                mensagensNaoLidas={activeTab === 1 ? 0 : mensagensNaoLidas}
                                bot={botsData?.runningBOT ? {
                                  id: botsData?.runningBOT.flowId,
                                  name: bots.find(el => el.id === botsData?.runningBOT.flowId)?.name || ''
                                } : null}
                                tasks={tasksCalled}
                                flagExcluida={flagExcluida}
                                // ([chat[chatAtivo]?.botsData?.runningBOT].filter(el => el)
                              />
                            );
                          })}
                      </FlipMove>
                    ) : (
                      ''
                    )}
                    {activeTab === 2 ? (
                      <FlipMove>
                        {contatos.map((prop, key) => {
                          const { nome, foto_perfil, id_whatsapp, id_contato } = contatos[key];
                          return (
                            <FunctionalArticle
                              key={id_contato}
                              registroDiaHora={''}
                              nome={nome || formataNumeroTelefone(id_whatsapp.split('@')[0])}
                              telefone={!nome ? '' : formataNumeroTelefone(id_whatsapp.split('@')[0])}
                              ultimaMensagem={''}
                              fotoPerfil={getFotoLink(foto_perfil)}
                              mensageiro={'whatsapp'}
                              ativo={false}
                              onClick={async () => {
                                const { value } = await Swal.fire({
                                  icon: 'question',
                                  title: `Deseja abrir o chamado?`,
                                  showCancelButton: true,
                                  cancelButtonText: 'Não',
                                  confirmButtonText: 'Sim',
                                });

                                if (value) {
                                  if (!conexoes.length) {
                                    await Swal.fire({
                                      title: 'Nenhuma conexão ativa.',
                                      text: 'Não existe nenhuma conexão ativa no sistema.',
                                      icon: 'warning',
                                    });
                                    return;
                                  }

                                  const tempChat = { ...chat };
                                  let { uuid: conexaoEnvio, id_whatsapp_conexao: conexaoEnvioID } = conexoes[0];

                                  if (conexoes.length > 1) {
                                    const conexoesTemp = {};
                                    const conexoesTempId = {};
                                    for (let i = 0; i < conexoes.length; i++) {
                                      const { uuid, conta_nome, id_whatsapp_conexao } = conexoes[i];
                                      conexoesTemp[uuid] = conta_nome;
                                      conexoesTempId[uuid] = id_whatsapp_conexao;
                                    }
                                    const { value: conexao } = await Swal.fire({
                                      title: 'Escolha a conexão para realizar o envio',
                                      input: 'select',
                                      inputOptions: conexoesTemp,
                                      inputPlaceholder: 'Selecione a conexão',
                                      showCancelButton: false,
                                      allowOutsideClick: false,
                                      inputValidator: value =>
                                        value ? '' : 'Selecione uma conexão para enviar a mensagem',
                                    });
                                    if (!conexao) return false;
                                    conexaoEnvio = conexao;
                                    conexaoEnvioID = conexoesTempId[conexao];
                                  }

                                  // *******************************************
                                  let [departamentoEnvio] = departamentosUsuario;
                                  if (departamentosUsuario.length > 1) {
                                    const departamentosKey = {};
                                    departamentos.map(prop => {
                                      const { id_departamento, nome } = prop;
                                      if (departamentosUsuario.indexOf(id_departamento) > -1)
                                        departamentosKey[id_departamento] = nome;
                                    });
                                    const { value: departamentoSelecionado } = await Swal.fire({
                                      title: 'Selecione o departamento para envio',
                                      input: 'select',
                                      inputOptions: departamentosKey,
                                      inputPlaceholder: 'Selecione o departamento',
                                      showCancelButton: true,
                                      inputValidator: value => (value ? '' : 'Selecione um departamento válido'),
                                    });

                                    if (!departamentoSelecionado) return false;
                                    departamentoEnvio = departamentoSelecionado;
                                  }

                                  if (!tempChat[id_contato]) {
                                    tempChat[id_contato] = {
                                      uuidConexao: conexaoEnvio,
                                      id_whatsapp_conexao: conexaoEnvioID,
                                      id_contato,
                                      id_whatsapp,
                                      nome,
                                      foto_perfil,
                                      id_departamento_fk: departamentoEnvio,
                                      id_departamento: departamentoEnvio,
                                      dataUltimaMensagem: new Date(),
                                      mensageiro: 'whatsapp',
                                      mensagens: [],
                                    };
                                    chatCarregados[id_contato] = tempChat[id_contato];
                                    setChat(tempChat);
                                    setChatCarregados({ ...chatCarregados });
                                  }
                                  setActiveTab(1);
                                  setChatAtivo(id_contato);
                                }
                              }}
                            />
                          );
                        })}
                      </FlipMove>
                    ) : (
                      ''
                    )}
                    {activeTab === 2 && (
                      <div style={{ width: '100%', marginTop: 20 }} align={'center'}>
                        <Tooltip title={'Carregar mais'}>
                          <IconButton style={{ color: primaryColor[0] }} onClick={carregarMaisContatos.bind(this)}>
                            <History />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {activeTab === 3 ? (
                      <FlipMove>
                        {grupos
                          .filter(el => {
                            if (!filter) return true;
                            return el.nome.toLowerCase().includes(filter.toLowerCase());
                          })
                          // filter
                          .filter(el => {
                            const { id_department_fk, id_user_fk } = el;
                            const { id_departamento_fk: departamentos, id_usuario } = usuarios.find(
                              el => el.id_usuario === me.id_usuario,
                            );
                            const flag_gestor = JSON.parse(localStorage.getItem('usuario_gestor') || 'false');
                            if (flag_gestor) return true;

                            if (id_department_fk) {
                              const permission = (departamentos || '')
                                .split(',')
                                .map(prop => parseFloat(prop))
                                .includes(parseFloat(id_department_fk));
                              if (!permission) return false;
                            }
                            if (id_user_fk) return parseFloat(id_user_fk) === parseFloat(id_usuario);
                            return true;
                          })
                          .map(prop => {
                            prop.mensagens = prop.mensagens.sort((a, b) => {
                              const { data: da } = a;
                              const { data: db } = b;
                              // if (!da || !db) return 0;
                              return new Date(da) > new Date(db) ? 1 : new Date(da) < new Date(db) ? -1 : 0;
                            });
                            return prop;
                          })
                          // .filter(el => el.mensagens.filter(el => el.data).length)
                          .sort((a, b) => {
                            const [da] = a.mensagens.slice(-1);
                            const [db] = b.mensagens.slice(-1);

                            // Se ambos os chats não têm mensagens, eles são considerados iguais
                            if (!da && !db) return 0;

                            // Chats com mensagens são priorizados acima dos chats sem mensagens
                            if (!da) return 1;
                            if (!db) return -1;

                            const { data: dataA } = da;
                            const { data: dataB } = db;

                            return new Date(dataA) > new Date(dataB) ? -1 : new Date(dataA) < new Date(dataB) ? 1 : 0;
                          })
                          .map((prop, key) => {
                            let {
                              id_whatsapp_grupo,
                              nome,
                              id_whatsapp_conexao_fk,
                              id_grupo_whatsapp,
                              id_cliente_fk,
                              foto_perfil,
                              id_ultima_mensagem_lida,
                              mensagens,
                              config
                            } = prop;

                            let ultimaMensagem = 'Nenhuma mensagem';
                            let dataUltimaMensagem = '';
                            let enviado = false;
                            let mensagensNaoLidas = 0;
                            mensagens = mensagens.filter(el => el.tipo !== 'sistema');
                            if (mensagens && mensagens.length) {
                              const { mensagem, data, tipo, flag_enviado } = mensagens[mensagens.length - 1];
                              dataUltimaMensagem = data;
                              enviado = flag_enviado;
                              ultimaMensagem = tipo === 'chat' ? mensagem : chatTiposTraducao[tipo] || tipo;
                              if (id_ultima_mensagem_lida) {
                                const mensagensOrdemID = mensagens.sort((a, b) =>
                                  a.id_mensagem < b.id_mensagem ? -1 : b.id_mensagem > a.id_mensagem ? 1 : 0,
                                );
                                mensagensNaoLidas = mensagensOrdemID
                                  .slice(
                                    mensagensOrdemID.map(prop => prop.id_mensagem).indexOf(id_ultima_mensagem_lida) +
                                      1 || -1,
                                  )
                                  .filter(el => {
                                    const {usuario} = el;
                                    if (!config || config.type !== 'private' || !usuario) return !el.flag_enviado;
                                    return (usuario.id_usuario !== me.id_usuario);
                                  }).length;

                              }
                              if (mensagens.length && !id_ultima_mensagem_lida) mensagensNaoLidas = mensagens.length;
                            }

                            let { tipo, mensagem, flag_enviado, usuario } = mensagens[mensagens.length - 1] || {};
                            if (config && config.type === 'private' && usuario)
                              flag_enviado = (usuario.id_usuario === me.id_usuario);

                            return (
                              <FunctionalArticle
                                key={id_whatsapp_grupo}
                                registroDiaHora={dataUltimaMensagem}
                                nome={nome || formataNumeroTelefone(id_grupo_whatsapp.split('@')[0])}
                                telefone={''}
                                ultimaMensagem={
                                  mensagens.length
                                    ? tipo === 'chat'
                                      ? mensagem
                                      : chatTiposTraducao[tipo] || tipo || ''
                                    : ''
                                }
                                enviado={flag_enviado}
                                fotoPerfil={getFotoLink(foto_perfil)}
                                mensageiro={'whatsapp'}
                                ativo={chatAtivo === id_grupo_whatsapp}
                                mensagensNaoLidas={mensagensNaoLidas}
                                disabled={localStorage.getItem('feature-groups') === 'true' ? false : key > 3}
                                onClick={async () => {
                                  if (localStorage.getItem('feature-groups') !== 'true' && key > 3) {
                                    Swal.fire({
                                      icon: 'question',
                                      title: 'Utiliza mais de 4 grupos?',
                                      text: 'Entre em contato com nosso time comercial e contrate agora mesmo!',
                                    });
                                  } else {
                                    setChatAtivo(id_grupo_whatsapp);
                                  }
                                  // carregarAnteriores(id_grupo_whatsapp);
                                }}
                              />
                            );
                          })}
                      </FlipMove>
                    ) : (
                      ''
                    )}
                    {activeTab === 1 ? (
                      <div style={{
                        width: gridChatsWidth,
                        zIndex: 9,
                        position: 'fixed',
                        bottom: 10
                      }}>
                        <Tabs
                            value={tabEspera}
                            onChange={(_, tab) => setTabEspera(tab)}
                            centered
                            TabIndicatorProps={{
                              style: {
                                display: 'none',
                                background: '#333',
                                opacity: 0.3,
                                height: '100%',
                                borderRadius: tabEspera === 0 ? '16px 0 0 16px' : '0 16px 16px 0'
                              }
                            }}
                        >
                          <Tooltip title={'Atendimentos em espera'}>
                            <Tab label={<>
                              {Object.keys(chat).map(prop => chat[prop]).filter(el => el.status === 'atendimento_pendente').filter(el => !el?.botsData?.runningBOT).length ? (
                                <span style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 'calc(50% - 25px)',
                                  background: 'rgba(255, 31, 15, 0.6)',
                                  width: 20,
                                  height: 20,
                                  fontSize: 12,
                                  borderRadius: '50%',
                                  color: '#FFF'
                                }}>
                                  {Object.keys(chat).map(prop => chat[prop]).filter(el => el.status === 'atendimento_pendente').filter(el => !el?.botsData?.runningBOT).length}
                                </span>
                              ) : ''}
                              <CgAlarm size={30} color={'#333'}/>
                            </>}
                            style={{background: '#DDD', borderRadius: '16px 0 0 16px', minWidth: 120}}/>
                          </Tooltip>
                          <Tooltip title={'Interação com BOT'}>
                            <Tab icon={
                              <>
                                {Object.keys(chat).map(prop => chat[prop]).filter(el => el.status === 'atendimento_pendente').filter(el => el?.botsData?.runningBOT).length ? (
                                  <span style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 'calc(50% - 25px)',
                                    background: 'rgba(255, 31, 15, 0.6)',
                                    width: 20,
                                    height: 20,
                                    fontSize: 12,
                                    borderRadius: '50%',
                                    color: '#FFF'
                                  }}>
                                    {Object.keys(chat).map(prop => chat[prop]).filter(el => el.status === 'atendimento_pendente').filter(el => el?.botsData?.runningBOT).length}
                                  </span>
                                ) : ''}
                                <FaRobot size={30} color={'#333'}/>
                              </>
                            }
                                 style={{background: '#DDD', borderRadius: '0 16px 16px 0', minWidth: 120}}/>
                          </Tooltip>
                        </Tabs>
                      </div>
                    ) : ''}
                  </CardBody>
                </Card>
                {/*{activeTab === 1 && (
                  <div className={classes.appBar}>
                    <TabsEspera value={valueTabEspera} onChange={handleChangeTabEspera}>
                      <Tooltip title="Automáticas do BOT">
                        <TabEspera icon={<EmojiPeople />} />
                      </Tooltip>
                      <Tooltip title="Tranferidas">
                        <TabEspera icon={<SwapHoriz />} />
                      </Tooltip>
                      <Tooltip title="Não escolheu opções do bot">
                        <TabEspera icon={<NotificationsPaused />} />
                      </Tooltip>
                    </TabsEspera>
                  </div>
                )}*/}
              </Paper>
            </Grid>
          )}
          {chatAtivo ? (
            // <Hidden only={chatAtivo ? ['xs', 'sm', 'lg', 'md'] : ['xs', 'sm']}>
            <Grid
              item
              xs={
                width === 'xs'
                  ? 12
                  : width === 'sm'
                  ? 12
                  : width === 'lg'
                  ? 8
                  : width === 'md'
                  ? 8
                  : width === 'xl' && 9
              }
            >
              <Paper className={classes.mainPaper}>
                <Grid container direction="row" className={classes.root} spacing={0} style={{ position: 'relative' }}>
                  <Grid item xs={12} className={classes.chatHeader}>
                    <div>
                      <IconButton
                        style={{
                          float: 'left',
                          marginTop: 5,
                          marginRight: -15,
                          color: '#FFF',
                        }}
                        onClick={() => setChatAtivo(0)}
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      <img
                        src={Object.keys(chat).length ? getFotoLink(chat[chatAtivo].foto_perfil) : defaultAvatar}
                        className={classes.chatProfilePicture}
                        onClick={() =>
                          Swal.fire({
                            imageUrl: Object.keys(chat).length
                              ? getFotoLink(chat[chatAtivo].foto_perfil)
                              : defaultAvatar,
                            imageAlt: Object.keys(chat).length ? chat[chatAtivo].nome : '',
                            confirmButtonText: 'Fechar',
                          })
                        }
                        style={{ cursor: 'pointer' }}
                        alt={'...'}
                      />
                      <div className={classes.chatTextHead}>
                        <h4 style={{ marginBottom: 0 }}>
                          <b>
                            {Object.keys(chat).length
                              ? chat[chatAtivo].nome || chat[chatAtivo].id_whatsapp.split('@')[0]
                              : ''}
                          </b>
                        </h4>
                        {/*<CardIconDescricao label={"WhatsApp"} icon={`fab fa-whatsapp`} color={'success'}/>*/}
                        <Hidden smDown={isMobile} mdDown={open}>
                          <CardIconDescricao
                            label={Object.keys(chat).length ? getConexaoNome(chat[chatAtivo]) : ''}
                            icon={`fas fa-mobile-alt`}
                          />
                        </Hidden>
                        <CardIconDescricao
                          label={
                            Object.keys(chat).length ? getDepartamentoLabelFromId(chat[chatAtivo].id_departamento) : ''
                          }
                          icon={`fas fa-sitemap`}
                          color={'gray'}
                        />
                      </div>
                    </div>

                    <div className={classes.chatIconsHead} style={{ position: 'relative' }}>
                      {openMediaOpcoes ? (
                        <FloatingMenu
                          slideSpeed={200}
                          direction="down"
                          spacing={8}
                          style={{
                            position: 'absolute',
                            zIndex: 99999999,
                            top: 40,
                            right: 54,
                          }}
                          isOpen={openMediaOpcoes}
                        >
                          <ChildButton
                            icon={<InsertDriveFile style={{ fontSize: 20, color: '#222' }} />}
                            size={40}
                            background={'#FFF'}
                            onClick={() => refInputUpload.click()}
                          />
                          <ChildButton
                            icon={<CameraAlt style={{ fontSize: 20, color: '#222' }} />}
                            size={40}
                            background={'#FFF'}
                            onClick={() => setModalCapturarOpen(true)}
                          />
                          <div
                            style={{
                              background: 'transparent',
                              width: '500px',
                              height: '100px',
                              position: 'absolute',
                              top: 100,
                              zIndex: -1,
                            }}
                            onMouseOut={() => setOpenMediaOpcoes(false)}
                          />
                        </FloatingMenu>
                      ) : null}

                      {isMobile || (width === 'md' && open) ? (
                        <>
                          <FloatingMenu
                            slideSpeed={200}
                            direction="down"
                            spacing={8}
                            style={{
                              position: 'absolute',
                              zIndex: 99999999,
                              top: 40,
                              right: 5,
                            }}
                            isOpen={openChamadoOpcoes}
                          >
                            <Tooltip title={'Transferir atendimento'}>
                              <ChildButton
                                icon={<SyncAlt style={{ fontSize: 20, color: '#222' }} />}
                                size={40}
                                background={'#FFF'}
                                onClick={() => {
                                  setModalTransferirChamado({
                                    ...modalTransferirChamado,
                                    id_chamado: chat[chatAtivo].id_chamado,
                                  });
                                  setOpenChamadoOpcoes(false);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title={`${open ? 'Fechar' : 'Abrir'} detalhes`}>
                              <ChildButton
                                icon={
                                  open ? (
                                    <Info style={{ fontSize: 20, color: '#222' }} />
                                  ) : (
                                    <InfoOutlined style={{ fontSize: 20, color: '#222' }} />
                                  )
                                }
                                size={40}
                                background={'#FFF'}
                                onClick={() => {
                                  handleDrawer();
                                  setOpenChamadoOpcoes(false);
                                }}
                              />
                            </Tooltip>
                            {Object.keys(chat).length && chat[chatAtivo].flag_atendimento ? (
                              <Tooltip title={'Midias'}>
                                <ChildButton
                                  icon={<InsertDriveFile style={{ fontSize: 20, color: '#222' }} />}
                                  size={40}
                                  background={'#FFF'}
                                  onClick={() => {
                                    refInputUpload.click();
                                    setOpenChamadoOpcoes(false);
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                            {Object.keys(chat).length && chat[chatAtivo].flag_atendimento ? (
                              <Tooltip title={'Frontal'}>
                                <ChildButton
                                  icon={<CameraAlt style={{ fontSize: 20, color: '#222' }} />}
                                  size={40}
                                  background={'#FFF'}
                                  onClick={() => {
                                    setModalCapturarOpen(true);
                                    setOpenChamadoOpcoes(false);
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </FloatingMenu>
                          {!chatAtivo.toString().includes('g.us') ? (
                            Object.keys(chat).length && chat[chatAtivo].flag_atendimento ? (
                              <>
                                <Tooltip title={'Finalizar atendimento'}>
                                  <IconButton onClick={() => setOpenModalEndCalled(true)}>
                                    <PhoneDisabledTwoTone />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title={'Iniciar atendimento'}>
                                  <IconButton onClick={iniciarAtendimento.bind(this)}>
                                    <PhoneEnabled />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={'Recusar atendimento'}>
                                  <IconButton onClick={recusarAtendimento.bind(this)}>
                                    <BlockIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )
                          ) : (
                            ''
                          )}
                          <Tooltip title={'Opções'}>
                            <IconButton onClick={() => setOpenChamadoOpcoes(!openChamadoOpcoes)}>
                              <MoreVert />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          {/*{(chatAtivo ? (chat[chatAtivo].id_chamado && chat[chatAtivo].flag_atendimento) : "") ? (*/}
                          {!chatAtivo.toString().includes('g.us') ? (
                            (chatAtivo ? (
                              chat[chatAtivo].id_chamado
                            ) : (
                              ''
                            )) ? (
                              <Tooltip title={'Transferir atendimento'}>
                                <IconButton
                                  onClick={() =>
                                    setModalTransferirChamado({
                                      ...modalTransferirChamado,
                                      id_chamado: chatAtivo ? chat[chatAtivo].id_chamado : '',
                                    })
                                  }
                                >
                                  <SyncAlt />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {!chatAtivo.toString().includes('g.us') ? (
                            Object.keys(chat).length && chat[chatAtivo].flag_atendimento ? (
                              <>
                                <Tooltip title={'Finalizar atendimento'}>
                                  <IconButton onClick={() => setOpenModalEndCalled(true)}>
                                    <PhoneDisabledTwoTone />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={'Arquivos e mídias'}>
                                  <IconButton onClick={() => setOpenMediaOpcoes(!openMediaOpcoes)}>
                                    <AttachFile />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title={'Iniciar atendimento'}>
                                  <IconButton onClick={iniciarAtendimento.bind(this)}>
                                    <PhoneEnabled />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={'Recusar atendimento'}>
                                  <IconButton onClick={recusarAtendimento.bind(this)}>
                                    <BlockIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )
                          ) : (
                            ''
                          )}
                          <Tooltip title={`${open ? 'Fechar' : 'Abrir'} detalhes`}>
                            <IconButton onClick={handleDrawer.bind(this)}>
                              {open ? <Info /> : <InfoOutlined />}
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        background: '#333',
                        height: 70,
                        width: 5,
                        zIndex: 1,
                        left: -5
                      }}
                    />
                    <div className={'inverted-border-radius'} style={{position: 'absolute', top: -40}}/>
                    <Container fixed>
                      {/* <Popover
                        open={popOpcoesMensagem.open}
                        anchorEl={popOpcoesMensagem.ref}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      >
                        <ClickAwayListener
                          onClickAway={() => setPopOpcoesMensagem({
                            open: false,
                            ref: null,
                            id_mensagem: null,
                            mensagem: null
                          })}
                        >
                          <List component="nav">
                            {[
                              {
                                label: 'Responder',
                                f: async () => {
                                  setIdMensagemResposta({
                                    id: popOpcoesMensagem.id_mensagem,
                                    texto: popOpcoesMensagem.mensagem
                                  });
                                  setPopOpcoesMensagem({ open: false, ref: null, id_mensagem: null, mensagem: null });
                                },
                                icon: Reply
                              },
                              // {label: 'Encaminhar', f: () => "", icon: Forward},
                              // {label: 'Apagar', f: () => "", icon: Delete}
                            ].map((prop, key) => {
                              const { label, f, icon: IconComponent } = prop;

                              return (
                                <ListItem button key={`lita_opc_${key}`} onClick={f}>
                                  <ListItemIcon>
                                    <IconComponent />
                                  </ListItemIcon>
                                  <ListItemText primary={label} />
                                </ListItem>
                              );
                            })}
                          </List>
                        </ClickAwayListener>
                      </Popover> */}
                      <Dropzone onDrop={carregarArquivoUpload.bind(this)}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <section>
                            <div {...getRootProps()}>
                              <Typography component="div" className={classes.chatHeight} id={'div-chat-principal'}>
                                <input
                                  {...getInputProps()}
                                  ref={ref => (refInputUpload = ref)}
                                  onClick={e => (e.target.value = null)}
                                />
                                {Object.keys(chat).length && !chat[chatAtivo].carregadoTotal && (
                                  <div style={{ width: '100%', marginTop: 20 }} align={'center'}>
                                    <IconButton
                                      style={{
                                        color: primaryColor[0],
                                        backgroundColor: '#FFF',
                                      }}
                                      onClick={() => carregarAnteriores()}
                                    >
                                      <History />
                                    </IconButton>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: isDragActive ? 'block' : 'none',
                                    // display: 'block',
                                    height: '100%',
                                    width: '100%',
                                    position: 'fixed',
                                    backgroundColor: 'black',
                                    zIndex: 5,
                                    opacity: 0.4,
                                    marginTop: -68,
                                    // borderRadius: 5,
                                    marginLeft: -33,
                                    transition: '.3s',
                                  }}
                                />
                                <p>&nbsp;</p>
                                {Object.keys(chat).length
                                  ? chat[chatAtivo].mensagens
                                      .sort((a, b) => {
                                        const { data: da } = a;
                                        const { data: db } = b;
                                        return new Date(da) > new Date(db) ? 1 : new Date(da) < new Date(db) ? -1 : 0;
                                      })
                                      .map((prop, key) => {
                                        let {
                                          flag_enviado,
                                          data,
                                          mensagem,
                                          tipo,
                                          dados,
                                          warning,
                                          caption,
                                          localizacao,
                                          id_mensagem,
                                          mensagemResposta,
                                          ack,
                                          flag_excluida,
                                          membro_grupo_nome,
                                          membro_grupo_numero,
                                          usuario,
                                          raw_message_data,
                                          edicoes
                                        } = prop;
                                        let notifyMessage = false
                                        if (chat[chatAtivo].id_whatsapp.toString().includes('g.us') && flag_enviado) {
                                          if (usuario && usuario.id_usuario !== me.id_usuario) {
                                            flag_enviado = false;
                                            notifyMessage = true;
                                          }
                                        }

                                        const position = flag_enviado ? 'right' : 'left';
                                        const isMedia =
                                          ['photo', 'location', 'video', 'document', 'sticker'].indexOf(
                                            chatTypes[tipo],
                                          ) > -1;

                                        let CardMensagem = <p key={key}>Mensagem tipo: {tipo}</p>;
                                        let locationImage = '';

                                        if (tipo === 'location') {
                                          locationImage = mensagem;
                                          caption, (mensagem = ``);
                                        }

                                        if (tipo === 'fileUpload') {
                                          CardMensagem = (
                                            <div className={classes.messageContainer}>
                                              <div
                                                className={classes.nessageBox}
                                                style={{
                                                  background: 'linear-gradient(45deg, #252525, #444444)',
                                                }}
                                              >
                                                <MessageBoxDocument
                                                  identifier={id_mensagem}
                                                  caption={''}
                                                  user={null}
                                                  fileName={prop.fileName}
                                                  mimetype={prop.mimeType}
                                                  progress={prop.progress}
                                                />
                                              </div>
                                            </div>
                                          );
                                        }

                                        if (['sistema', 'call_log', 'e2e_notification'].indexOf(tipo) > -1) {
                                          CardMensagem = (
                                            <SystemMessage
                                              text={`${mensagem}`}
                                              key={`${id_mensagem}_${key}`}
                                              className={warning ? 'rce-smsg-warning' : ''}
                                            />
                                          );
                                        } else if (
                                          ['templateMessage', 'buttonsMessage'].includes(tipo) &&
                                          raw_message_data
                                        ) {
                                          const buttonMessage = {
                                            title: '',
                                            footer: '',
                                            buttons: [],
                                            template: false,
                                          };
                                          try {
                                            if (tipo === 'templateMessage') {
                                              const {
                                                templateMessage: {
                                                  hydratedTemplate: {
                                                    hydratedButtons,
                                                    hydratedContentText,
                                                    hydratedFooterText,
                                                  },
                                                },
                                              } = raw_message_data;
                                              buttonMessage.template = true;
                                              buttonMessage.title = hydratedContentText;
                                              buttonMessage.footer = hydratedFooterText;
                                              buttonMessage.buttons = hydratedButtons.map(prop => ({
                                                id: prop.quickReplyButton.id,
                                                text: prop.quickReplyButton.displayText,
                                              }));
                                            }
                                            if (tipo === 'buttonsMessage') {
                                              const {
                                                buttonsMessage: { buttons, footerText, contentText },
                                              } = raw_message_data;
                                              buttonMessage.title = contentText;
                                              buttonMessage.footer = footerText;
                                              buttonMessage.buttons = buttons.map(prop => ({
                                                id: prop.buttonId,
                                                text: prop.buttonText.displayText,
                                              }));
                                            }
                                            CardMensagem = (
                                              <div
                                                style={
                                                  !!flag_enviado
                                                    ? {
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'end',
                                                      }
                                                    : {}
                                                }
                                                key={`${id_mensagem}_${key}`}
                                              >
                                                <MessageBox
                                                  className={`message-list mensagem_${id_mensagem} ${notifyMessage ? 'message-notify' : ''}`}
                                                  key={`${id_mensagem}_${key}`}
                                                  dateString={convertDate(data, false, true, true)}
                                                  status={flag_enviado ? ackStatusCard[ack] || 'sent' : 'none'} //waiting, sent, received, read
                                                  position={position}
                                                  type={'text'}
                                                  text={
                                                    <div>
                                                      {buttonMessage.template ? (
                                                        <small
                                                          style={{
                                                            fontSize: 9,
                                                            background: 'rgba(255,0,0,0.2)',
                                                          }}
                                                        >
                                                          Mensagem duplicada para compatibilidade de dispositivos
                                                          <br />
                                                        </small>
                                                      ) : (
                                                        ''
                                                      )}
                                                      <span>{buttonMessage.title}</span>
                                                      <br />
                                                      <small
                                                        style={{
                                                          color: '#777',
                                                        }}
                                                      >
                                                        {buttonMessage.footer}
                                                      </small>
                                                      {buttonMessage.buttons.map(prop => {
                                                        const { id, text } = prop;
                                                        return (
                                                          <div
                                                            style={{
                                                              padding: 10,
                                                              backgroundColor: 'rgba(0,0,0, 0.3)',
                                                              borderRadius: 5,
                                                              margin: '5px 0px',
                                                              textAlign: 'center',
                                                            }}
                                                          >
                                                            {text}
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                  }
                                                />
                                              </div>
                                            );
                                          } catch (e) {}
                                          // CardMensagem = JSON.stringify(raw_message_data);
                                        } else if (Object.keys(chatTypes).indexOf(tipo) > -1) {
                                          let resposta = null;
                                          if (mensagemResposta) {
                                            const isMediaResposta =
                                              ['photo', 'location', 'video', 'document', 'sticker'].indexOf(
                                                mensagemResposta.tipo,
                                              ) > -1;

                                            resposta = {
                                              photoURL: isMediaResposta
                                                ? `${apiURL}/chat/getMediaFromMessageID?id_mensagem=${
                                                    mensagemResposta.id_mensagem
                                                  }&token=${ls('token')}`
                                                : undefined,
                                              title: mensagemResposta.nome,
                                              titleColor: infoColor[0],
                                              message: mensagemResposta.body || mensagemResposta.caption,
                                            };
                                          }

                                          if (membro_grupo_numero && !colorsContact[membro_grupo_numero]) {
                                            setColorsContact(cc => {
                                              cc[membro_grupo_numero] =
                                                '#' + Math.floor(Math.random() * 16777215).toString(16);
                                              return { ...cc };
                                            });
                                          }

                                          CardMensagem = (
                                            <div
                                              style={
                                                !!flag_enviado
                                                  ? {
                                                      display: 'flex',
                                                      // float: 'right',
                                                      width: '100%',
                                                      justifyContent: 'end',
                                                    }
                                                  : {}
                                              }
                                              key={`${id_mensagem}_${key}`}
                                            >
                                              <MessageBox
                                                class
                                                reply={resposta}
                                                onReplyMessageClick={async () => {
                                                                                   const {
                                                                                     id_mensagem,
                                                                                   } = mensagemResposta;
                                                                                   await carregarAnteriores(
                                                                                     id_mensagem,
                                                                                   );
                                                                                   document
                                                                                     .getElementById(
                                                                                       'div-chat-principal',
                                                                                     )
                                                                                     .scrollTo(
                                                                                       0,
                                                                                       document.getElementsByClassName(
                                                                                         `mensagem_${id_mensagem}`,
                                                                                       )[0].offsetTop -
                                                                                         document.getElementById(
                                                                                           'div-chat-principal',
                                                                                         ).offsetHeight /
                                                                                           2,
                                                                                     );
                                                                                   document.getElementsByClassName(
                                                                                     `mensagem_${id_mensagem}`,
                                                                                   )[0].style.borderRadius = '15px';
                                                                                   // document.getElementsByClassName(`mensagem_${id_mensagem}`)[0].style.background = `rgba(196, 227, 243, 0.90)`;
                                                                                   document.getElementsByClassName(
                                                                                     `mensagem_${id_mensagem}`,
                                                                                   )[0].style.background = `rgba(${hexToRgb(
                                                                                     primaryColor[0],
                                                                                   )}, 0.70)`;
                                                                                   setTimeout(async () => {
                                                                                     const timeout = time =>
                                                                                       new Promise(resolve =>
                                                                                         setTimeout(resolve, time),
                                                                                       );
                                                                                     for (let i = 70; i > 0; i--) {
                                                                                       document.getElementsByClassName(
                                                                                         `mensagem_${id_mensagem}`,
                                                                                       )[0].style.background = `rgba(${hexToRgb(
                                                                                         primaryColor[0],
                                                                                       )}, 0.${zeroEsquerda(i)})`;
                                                                                       await timeout(30);
                                                                                     }
                                                                                     document.getElementsByClassName(
                                                                                       `mensagem_${id_mensagem}`,
                                                                                     )[0].style.background = `none`;
                                                                                   }, 1300);
                                                                                 }}
                                                className={`message-list mensagem_${id_mensagem} ${notifyMessage ? 'message-notify' : ''}`}
                                                key={`${id_mensagem}_${key}`}
                                                // forwarded
                                                dateString={convertDate(data, false, true, true)}
                                                status={flag_enviado ? ackStatusCard[ack] || 'sent' : 'none'} //waiting, sent, received, read
                                                position={position}
                                                title={
                                                  tipo === 'document' && (caption && mensagem) ? caption : undefined
                                                }
                                                type={chatTypes[tipo]}
                                                // dateString={}
                                                // type={'text'}
                                                // renderAddCmp={() => (<div>'Teste'</div>)}
                                                // retracted={true}
                                                text={
                                                  <>
                                                    {edicoes && edicoes.length > 0 ? (
                                                        <Tooltip
                                                            title={
                                                              <div style={{ maxWidth: '200px', wordBreak: 'break-word' }}>
                                                                <strong>Texto anterior: </strong>
                                                                <br />
                                                                <em>{edicoes[edicoes.length - 1]?.oldText || 'Sem alterações'}</em>
                                                                <br />
                                                                <small>{convertDate(edicoes[edicoes.length - 1]?.date, false, true, true) || ''}</small>
                                                              </div>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                          <div>
                                                            <span style={{color: '#888', fontSize: '0.85rem'}}> <Edit
                                                                style={{fontSize: 16}}/> Editada</span>
                                                            {membro_grupo_nome && (<br/>)}
                                                          </div>
                                                        </Tooltip>
                                                    ) : null}
                                                    {/*{<MessageBox type={'file'}/>}*/}
                                                    {membro_grupo_nome ? (
                                                      <>
                                                        <span
                                                          style={{
                                                            color: colorsContact[membro_grupo_numero],
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={async () => {
                                                            const { value } = await Swal.fire({
                                                              icon: 'question',
                                                              title: `Deseja enviar mensagem para ${membro_grupo_nome}?`,
                                                              confirmButtonText: 'Sim',
                                                              showConfirmButton: true,
                                                              cancelButtonText: 'Não',
                                                              showCancelButton: true,
                                                            });

                                                            if (value)
                                                              abrirChatDireto(
                                                                membro_grupo_numero.split('@')[0],
                                                                '',
                                                                membro_grupo_nome,
                                                              );
                                                          }}
                                                        >
                                                          {membro_grupo_nome}
                                                        </span>
                                                        <br />
                                                        <br />
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {/* {!isMedia ? getOpcoesMenuChat(id_mensagem, caption, mensagem) : ""} */}
                                                    {/*{isMedia ? caption : (*/}
                                                    {flag_excluida && !isMedia ? (
                                                      <Tooltip title={'Clique para ver a mensagem original'}>
                                                        <span
                                                          style={{
                                                            color: '#777',
                                                            fontWeight: 500,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            fontSize: 14,
                                                            cursor: 'pointer'
                                                          }}
                                                          onClick={() => {
                                                            Swal.fire({html: '<div style="white-space: pre;text-align: left;background: #CCC;border-radius: 0 25px 25px 25px;padding: 5px 10px;">' +
                                                                converterTextoWpp((!isMedia && mensagem ? mensagem : caption) || mensagem) +
                                                              '</div>' })
                                                          }}
                                                        >
                                                          <MdBlock/> Mensagem excluída
                                                        </span>
                                                      </Tooltip>
                                                    ) : (
                                                    <div
                                                      title={flag_excluida ? 'Essa mensagem foi excluida' : null}
                                                      className="rce-mbox-text"
                                                      style={{
                                                        marginBottom: 0,
                                                        textDecoration: flag_excluida ? 'line-through' : '',
                                                        color: flag_excluida ? '#FF0000' : '',
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html: converterTextoWpp(
                                                          (raw_message_data &&
                                                            raw_message_data.buttonsResponseMessage &&
                                                            raw_message_data.buttonsResponseMessage.selectedDisplayText) ||
                                                            (!isMedia && mensagem ? mensagem : caption) ||
                                                            mensagem,
                                                        ),
                                                      }}
                                                    />
                                                    )}
                                                  </>
                                                }
                                                onClick={({ target }) => {
                                                  if (
                                                    target.parentElement.className !== 'rce-mbox-photo--img' &&
                                                    target.className !== 'rce-mbox-photo--img__block'
                                                  )
                                                    return;
                                                  if (tipo === 'location')
                                                    window.open(
                                                      `http://maps.google.com/maps?q=loc:${localizacao.lat},${localizacao.lng}`,
                                                    );

                                                  if (tipo === 'image' && !idMensagemResposta.id) {
                                                    setLoading(true);
                                                    if (loading === false) {
                                                      let base64image = getBase64Image(prop.mediaURL).then(function(
                                                        base64image,
                                                      ) {
                                                        setLoading(false);
                                                        setModalImagem({
                                                          open: true,
                                                          url: `data:image/jpeg;base64,${base64image}`,
                                                        });
                                                      });
                                                    }

                                                    function getBase64Image(imgUrl) {
                                                      return new Promise(function(resolve, reject) {
                                                        var img = new Image();
                                                        img.src = imgUrl;
                                                        img.setAttribute('crossOrigin', 'anonymous');

                                                        img.onload = function() {
                                                          var canvas = document.createElement('canvas');
                                                          canvas.width = img.width;
                                                          canvas.height = img.height;
                                                          var ctx = canvas.getContext('2d');
                                                          ctx.drawImage(img, 0, 0);
                                                          var dataURL = canvas.toDataURL('image/jpeg');
                                                          resolve(
                                                            dataURL.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
                                                          );
                                                        };
                                                        img.onerror = function() {
                                                          reject('The image could not be loaded.');
                                                        };
                                                      });
                                                    }
                                                  }
                                                }}
                                                // react-chat-elements
                                                removeButton={!!flag_enviado && !flag_excluida}
                                                // removeButton={!flag_excluida}
                                                replyButton={!flag_excluida}
                                                /*renderAddCmp={() => {
                                                  if (flag_enviado) return;
                                                  return (
                                                    <IconButton
                                                      className={classes.iconButton}
                                                      style={{background: '#FFF', boxShadow: '#000 0 0 10px -5px'}}
                                                      aria-label="menu"
                                                      ref={ref => setAnchorIA(ref)}
                                                      onClick={() => setOpenIAMenu(!openIAMenu)}
                                                    >
                                                      <img src={AutoAwesome} alt={'download'} style={{ filter: 'invert(0.75)', height: 20 }}/>
                                                    </IconButton>
                                                  );
                                                }}*/
                                                onRemoveMessageClick={async () => {
                                                  if (flag_enviado) {
                                                    // Excluir mensagem
                                                    const { value } = await Swal.fire({
                                                      icon: 'question',
                                                      title: 'Deseja realmente excluir a mensagem?',
                                                      showCancelButton: true,
                                                      showConfirmButton: true,
                                                      cancelButtonText: 'Não',
                                                      confirmButtonText: 'Sim',
                                                    });
                                                    if (!value) return;

                                                    await WebService(`/whatsapp_api/deleteMessage`, { id_mensagem });
                                                  } else {
                                                    // Processa I.A.
                                                  }
                                                }}
                                                onReplyClick={async () => {
                                                  setIdMensagemResposta({
                                                    id: id_mensagem,
                                                    texto: isMedia ? (
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          justifyContent: 'flex-start',
                                                        }}
                                                      >
                                                        <img
                                                          style={{
                                                            height: '60px',
                                                            width: '60px',
                                                          }}
                                                          src={prop.mediaURL}
                                                        />
                                                        {isMedia ? (
                                                          caption
                                                        ) : (
                                                          <div
                                                            dangerouslySetInnerHTML={{
                                                              __html: converterTextoWpp(caption || mensagem),
                                                            }}
                                                          />
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html: converterTextoWpp(caption || mensagem),
                                                        }}
                                                      />
                                                    ),
                                                  });
                                                }}
                                                onDownload={async () => {
                                                  const requestReturn = await fetch(prop.mediaURL);
                                                  const dataBlob = await requestReturn.blob();
                                                  downloadFileAsBlob(dataBlob, mensagem || caption);
                                                }}
                                                data={{
                                                  uri:
                                                    tipo === 'location'
                                                      ? `data:image/jpeg;base64,${locationImage}`
                                                      : prop.mediaURL,
                                                  status: {
                                                    click: !isMedia || tipo !== 'photo',
                                                    loading: 0,
                                                  },
                                                  latitude: tipo === 'location' && localizacao.lat,
                                                  longitude: tipo === 'location' && localizacao.lng,
                                                }}
                                              />
                                              {/*{new Date(data)}*/}
                                              {!!flag_enviado &&
                                              new Date(data).getTime() > new Date('2023-01-01 00:00:00').getTime() ? (
                                                <Tooltip title={usuario ? usuario.nome : 'BOT'}>
                                                  <img
                                                    style={{
                                                      width: 30,
                                                      height: 30,
                                                      borderRadius: 30,
                                                    }}
                                                    src={usuario ? getFotoLink(usuario.foto_perfil) : Robot}
                                                    alt={usuario ? usuario.nome : 'BOT'}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          );
                                        } else if (tipo === 'loading') {
                                          CardMensagem = (
                                            <MessageBox
                                              key={`${id_mensagem}_${key}`}
                                              position={'right'}
                                              status={'waiting'}
                                              dateString={'Agora'}
                                              text={<SyncLoader size={10} color={infoColor[0]} loading={true} />}
                                            />
                                          );
                                        } else if (tipo === 'video') {
                                          CardMensagem = (
                                            <MessageBox
                                              key={`${id_mensagem}_${key}`}
                                              //forwarded
                                              position={position}
                                              dateString={convertDate(data, false, true, true)}
                                              status={'sent'} //waiting, sent, received, read
                                              type={'text'}
                                              className={notifyMessage ? 'message-notify' : ''}
                                              text={
                                                <>
                                                  {/*{getOpcoesMenuChat(id_mensagem, caption, mensagem)}*/}
                                                  <video
                                                    controls
                                                    style={{
                                                      width: '100%',
                                                      maxHeight: 300,
                                                    }}
                                                  >
                                                    <source src={prop.mediaURL} type="video/mp4" />
                                                  </video>
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: converterTextoWpp(caption || mensagem),
                                                    }}
                                                  />
                                                </>
                                              }
                                              replyButton
                                              onReplyClick={async () => {
                                                setIdMensagemResposta({
                                                  id: id_mensagem,
                                                  texto: (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                      }}
                                                    >
                                                      <Movie />
                                                      Vídeo
                                                    </div>
                                                  ),
                                                });
                                              }}
                                            />
                                          );
                                        } else if (tipo === 'vcard') {
                                          let contacts = [{ vcard: mensagem }];
                                          try {
                                            contacts = JSON.parse(mensagem);
                                          } catch (e) {}
                                          CardMensagem = [];
                                          for (let j = 0; j < contacts.length; j += 1) {
                                            // console.log(contacts[j]); // TODO - Não exibir logs em produção
                                            const card = vCard.parse(contacts[j].vcard);
                                            const {
                                              fn: [{ value: nome }],
                                              tel,
                                            } = card;

                                            if (!tel) {
                                              CardMensagem.push('Contato com formáto inválido');
                                            } else {
                                              const [{ value: telefone, meta }] = tel;
                                              let wid;
                                              if (meta) {
                                                const { waid } = meta;
                                                if (waid) wid = waid[0];
                                              }
                                              CardMensagem.push(
                                                <MessageBox
                                                  key={`${id_mensagem}_${key}`}
                                                  //forwarded
                                                  position={position}
                                                  dateString={convertDate(data, false, true, true)}
                                                  status={'sent'} //waiting, sent, received, read
                                                  type={'text'}
                                                  className={notifyMessage ? 'message-notify' : ''}
                                                  text={
                                                    <>
                                                      <p
                                                        style={{
                                                          marginTop: 10,
                                                          marginBottom: 0,
                                                        }}
                                                      >
                                                        <strong>{nome}</strong>
                                                      </p>
                                                      <p style={{ margin: 0 }}>{telefone}</p>
                                                      {!!wid && (
                                                        <Button
                                                          color={'hust'}
                                                          onClick={() => abrirChatDireto(telefone, wid, nome)}
                                                          fullWidth
                                                          round
                                                        >
                                                          Enviar mensagem
                                                        </Button>
                                                      )}
                                                    </>
                                                  }
                                                  replyButton
                                                  onReplyClick={async () => {
                                                    setIdMensagemResposta({
                                                      id: id_mensagem,
                                                      texto: (
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                          }}
                                                        >
                                                          <Person />
                                                          <p>
                                                            <strong>
                                                              {nome} <br />
                                                            </strong>
                                                            {telefone}
                                                          </p>
                                                        </div>
                                                      ),
                                                    });
                                                  }}
                                                />,
                                              );
                                            }
                                          }
                                        } else if (['audio', 'ptt'].indexOf(tipo) > -1) {
                                          CardMensagem = (
                                            <MessageBox
                                              key={`${id_mensagem}_${key}`}
                                              //forwarded
                                              position={position}
                                              dateString={convertDate(data, false, true, true)}
                                              status={'sent'} //waiting, sent, received, read
                                              type={'text'}
                                              className={notifyMessage ? 'message-notify' : ''}
                                              text={
                                                <>
                                                  {/* {getOpcoesMenuChat(id_mensagem, caption, mensagem)} */}
                                                  <audio controls preload="metadata" style={{ maxWidth: 270 }}>
                                                    <source src={prop.mediaURL} type="audio/mp3" />
                                                  </audio>
                                                  {caption && (
                                                    <div
                                                      style={{
                                                        background: `rgba(0, 0, 0, 0.3)`,
                                                        padding: '6px 10px',
                                                        lineHeight: 1,
                                                        borderRadius: 10,
                                                        textAlign: 'justify',
                                                      }}
                                                    >
                                                      <span title={'Essa é uma transcrição do áudio'}>
                                                        {caption}
                                                        {/*<br/><br/>
                                                  <small
                                                    style={{
                                                      display: "block",
                                                      width: '100%',
                                                      fontSize: 10,
                                                      color: '#800'
                                                    }}
                                                  >
                                                    Para obter uma transcrição de áudio precisa, entre em contato com
                                                    nossa equipe de vendas e contrate nossa inteligência artificial.
                                                  </small>*/}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              }
                                              replyButton
                                              onReplyClick={async () => {
                                                setIdMensagemResposta({
                                                  id: id_mensagem,
                                                  texto: (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                      }}
                                                    >
                                                      <MicNoneTwoTone />
                                                      Áudio
                                                    </div>
                                                  ),
                                                });
                                              }}
                                            />
                                          );
                                        }

                                        const mudouDia =
                                          !key ||
                                          new Date(data).getDate() !==
                                            new Date(chat[chatAtivo]['mensagens'][key - 1].data).getDate();
                                        if (mudouDia)
                                          CardMensagem = (
                                            <>
                                              <SystemMessage
                                                text={`${convertDate(data)}`}
                                                key={`${key}_c`}
                                                className={'rce-smsg-date'}
                                              />
                                              {CardMensagem}
                                            </>
                                          );
                                        return CardMensagem;
                                      })
                                  : ''}

                                {/*<SystemMessage text={"Início do atendimento"}/>
                                  {new Array(20).fill(null).map((prop, key) => (
                                    <MessageList
                                      className="message-list"
                                      lockable={true}
                                      toBottomHeight={"100%"}
                                      dataSource={[
                                        {
                                          position: (key % 2 === 0) ? "left" : 'right',
                                          type: "text",
                                          statusColor: "#000",
                                          text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit Lorem ipsum dolor sit amet, consectetur adipisicing elit",
                                          date: new Date()
                                        }
                                      ]}
                                    />
                                  ))}*/}
                              </Typography>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Container>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      position: 'absolute',
                      bottom: '-50px',
                      width: '100%',
                    }}
                  >
                    <Container fixed>
                      <Typography component="div" className={classes.chatInputMessage} style={{ boxShadown: '#000 0px 0px 40px -15px' }}>
                        <Popper open={openEmoji} anchorEl={anchorEl} placement="top-start">
                          <ClickAwayListener onClickAway={() => setOpenEmoji(false)}>
                            <Picker
                              onSelect={addEmoji}
                              showPreview={false}
                              showSkinTones={false}
                              style={{ top: '150px' }}
                              // native
                              // set={'google'}
                              i18n={{
                                search: 'Pesquisar',
                                clear: 'Limpar', // Accessible label on "clear" button
                                notfound: 'Nenhum Emoji encontrado',
                                skintext: 'Escolha o seu tom de pele padrão',
                                categories: {
                                  search: 'Resultados da pesquisa',
                                  recent: 'Usados frequentemente',
                                  smileys: 'Smileys & Emotion',
                                  people: 'Pessoas & Corpo',
                                  nature: 'Animais & Natureza',
                                  foods: 'Comida & bebidas',
                                  activity: 'Atividade',
                                  places: 'Viagens & Lugares',
                                  objects: 'Objetos',
                                  symbols: 'Simbolos',
                                  flags: 'Bandeiras',
                                  custom: 'Personalizadas',
                                },
                                categorieslabel: 'Emoji categorias', // Accessible title for the list of categories
                                skintones: {
                                  1: 'Tom de pele padrão',
                                  2: 'Pele clara',
                                  3: 'Pele clara média',
                                  4: 'Tom de pele médio',
                                  5: 'Pele morena escura',
                                  6: 'Pele escura',
                                },
                              }}
                            />
                          </ClickAwayListener>
                        </Popper>
                        <Popover
                          open={openIAMenu}
                          anchorEl={anchorIA}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                          <ClickAwayListener onClickAway={() => setOpenIAMenu(false)}>
                            <List component="nav">
                              {[
                                { label: 'Melhorar escrita', icon: BsMagic, type: 'ImproveWriting' },
                                { label: 'Corrigir ortografia e gramática', icon: BsCheck, type: 'CorrectSpellingAndGrammar' },
                                { label: 'Encurtar', icon: MdShortText, type: 'Shorten' },
                                { label: 'Alongar', icon: BsTextParagraph, type: 'Elongate' },
                                { label: 'Mudar tom', icon: GiMicrophone }, // ChangeTone
                                { label: 'Simplificar linguagem', icon: BsStars, type: 'SimplifyLanguage' },
                              ].map((prop, key) => (
                                <ListItem
                                  key={key}
                                  button
                                  disabled={!prop.type}
                                  onClick={() => {
                                    if (!text) {
                                      return Swal.fire({ icon: 'info', title: 'É necesário digitar um texto na caixa ao lado antes de aplicar uma ação.' });
                                    }
                                    setTextSugestionAI('...');
                                    getIAPromtResponseStream('/application/ai/text-action', {
                                      text,
                                      type: prop.type
                                    }, setTextSugestionAI);
                                    // getIAPromtResponseStream(prop.path, { text }, setText);
                                    setOpenIAMenu(false);
                                  }}
                                  style={{ padding: '0px 16px' }}
                                >
                                  <ListItemIcon style={{ minWidth: 45 }}>
                                    <prop.icon style={{ fontSize: 25 }}/>
                                  </ListItemIcon>
                                  <ListItemText primary={prop.label}/>
                                </ListItem>
                              ))}
                            </List>
                          </ClickAwayListener>
                        </Popover>
                        <Popover
                          open={openBotList}
                          anchorEl={anchorBOT}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                          <ClickAwayListener onClickAway={() => setOpenBotList(false)}>
                            <List component="nav">
                              {bots.map((prop, key) => (
                                <Tooltip title={'Iniciar BOT na conversa'} key={key}>
                                  <ListItem
                                    button
                                    onClick={async () => {
                                      const { value } = await Swal.fire({
                                        icon: "question",
                                        title: 'Deseja acionar o BOT na conversa?',
                                        confirmButtonText: 'Sim',
                                        showCancelButton: true,
                                        cancelButtonText: 'Não'
                                      });
                                      if (value) {
                                        const calledID = Number(chatAtivo ? chat[chatAtivo].id_chamado : '');
                                        await api.post(`/bot/${prop.id}/call`, {
                                          params: {},
                                          called: { id: calledID }
                                        });
                                      }
                                      setOpenBotList(false)
                                    }}
                                    style={{ padding: '0px 16px' }}
                                  >
                                    <ListItemText primary={prop.name}/>
                                    <ListItemIcon style={{ minWidth: 45, flexDirection: 'row-reverse' }}>
                                      <BsSendPlus style={{ fontSize: 25 }}/>
                                    </ListItemIcon>
                                  </ListItem>
                                </Tooltip>
                              ))}
                            </List>
                          </ClickAwayListener>
                        </Popover>
                        <IconButton
                          className={classes.iconButton}
                          aria-label="menu"
                          ref={ref => setAnchorEl(ref)}
                          onClick={() => setOpenEmoji(!openEmoji)}
                        >
                          <Mood />
                        </IconButton>
                        {checkBeta('text-review-ai') ? (
                          <IconButton
                            className={classes.iconButton}
                            aria-label="menu"
                            ref={ref => setAnchorIA(ref)}
                            onClick={() => setOpenIAMenu(!openIAMenu)}
                          >
                            <img src={AutoAwesome} alt={'download'} style={{ filter: 'invert(0.75)', height: 20 }}/>
                          </IconButton>
                        ) : ''}
                        <TextareaAutosize
                          // flag_enviar_mensagem
                          disabled={!((chatAtivo && chat[chatAtivo].flag_enviar_mensagem !== undefined) ? !!chat[chatAtivo].flag_enviar_mensagem : true)}
                          maxRows={4}
                          ref={refInputTextChat}
                          className={classes.input}
                          style={{ zIndex: 3 }}
                          value={text}
                          autoFocus={true}
                          spellCheck={true}
                          lang="pt-Br"
                          // placeholder="Escreva sua mensagem"
                          placeholder={((chatAtivo && chat[chatAtivo].flag_enviar_mensagem !== undefined) ? !!chat[chatAtivo].flag_enviar_mensagem : true) ? 'Escreva sua mensagem' : 'Não é possível enviar mensagem'}
                          inputprops={{ 'aria-label': 'escreva sua mensagem' }}
                          onKeyPress={event => {
                            if (window.event.keyCode === 13 && !window.event.shiftKey) {
                              event.preventDefault();
                              enviarMensagem();
                            }
                          }}
                          onChange={({ target: { value } }) => value !== '\n' && setText(value)}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        {checkBeta('text-review-ai') ? (
                          <IconButton
                            className={classes.iconButton}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            ref={ref => setAnchorBOT(ref)}
                            onClick={() => setOpenBotList(!openBotList)}
                          >
                            <FaRobot/>
                          </IconButton>
                        ) : ''}
                        <IconButton
                          className={classes.iconButton}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={async () => {
                            if (!text) setOpenRespostasRapidas(!openRespostasRapidas);
                            else {
                              const {value} = await Swal.fire({
                                icon: 'question',
                                title: 'Deseja salvar este texto como resposta rápida?',
                                html: `<pre style="text-align: left; background: #EEE; padding: 5px; border-radius: 10px;">${text}</pre><strong style="display: block; width: 100%; text-align: left;">Digite a TAG</strong>`,
                                input: "text",
                                showCancelButton: true,
                                confirmButtonText: 'Cadastrar',
                                cancelButtonText: 'Cancelar',
                                inputValidator: value => !((value || '').trim()) ? 'Digite uma TAG válida.' : null
                              });

                              if (!value) return;

                              const resposta = {mensagem: (text || '').trim(), chave: value.trim(), user: true};
                              const {ok, id_mensagem_predefinida} = await WebService(`/mensagem/atualizaMensagemPredefinida`, resposta, "POST");
                              if (!ok) return;

                              await Swal.fire({icon: "success", title: 'Mensagem rápida cadastrada com sucesso!'});
                              setText('');
                              getMensagensPredefinidas();
                            }
                          }}
                        >
                          <FlashOn />
                        </IconButton>
                        <List
                          style={{
                            transition: '.2s',
                            position: 'absolute',
                            width: isMobile ? '100%' : 'calc(100% - 60px)',
                            background: '#FFF',
                            maxHeight: 48 * 4.5,
                            overflow: 'auto',
                            bottom: openRespostasRapidas ? (isMobile ? 65 : 60) : -15,
                            right: isMobile ? 0 : 30,
                            visibility: openRespostasRapidas ? 'visible' : 'hidden',
                            // display: openRespostasRapidas ? 'block' : 'none',
                            zIndex: openRespostasRapidas ? 1000 : -1,
                            borderRadius: 10,
                            padding: 15,
                            boxShadow:
                              '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                          }}
                        >
                          {(openRespostasRapidas ? mensagensPredefinidas : [])
                            .filter(el => {
                              const { chave } = el;
                              if (!text || (text && text[0] !== '/')) return true;
                              else return `/${chave}`.indexOf(text) > -1;
                            })
                            .filter(el => {
                              if (!el.id_departamento_fk) return true;

                              return !!departamentosUsuario
                                .map(prop =>
                                  el.id_departamento_fk
                                    .split(',')
                                    .map(parseFloat)
                                    .includes(prop),
                                )
                                .filter(el => el).length;
                            })
                            .map((prop, key) => {
                              const { id_mensagem_predefinida, mensagem, chave, file } = prop;

                              return (
                                <ListItem
                                  style={{
                                    cursor: 'pointer',
                                    backgroundColor: key % 2 === 0 ? '#FFF' : '#F1F1F1',
                                    visibility: openRespostasRapidas ? 'visible' : 'hidden',
                                  }}
                                  key={key}
                                >
                                  <ListItemText
                                    onClick={() => {
                                      setText(mensagem);
                                      refInputTextChat.current.focus();
                                      refInputTextChat.current.select();
                                    }}
                                  >
                                    <strong>/{chave}</strong>
                                    &nbsp;&nbsp;&nbsp;
                                    {file ? (
                                      <span
                                        style={{
                                          position: 'absolute',
                                          bottom: 0,
                                          fontSize: 10,
                                        }}
                                      >
                                        {`${file.nome_arquivo}.${file.extencao}`}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    {mensagem}
                                  </ListItemText>
                                  <ListItemSecondaryAction>
                                    <Tooltip title={'Editar antes de enviar'}>
                                      <IconButton
                                        onClick={() => {
                                          setText(mensagem);
                                          refInputTextChat.current.focus();
                                          refInputTextChat.current.select();
                                        }}
                                      >
                                        <Edit color="primary" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Enviar mensagem'}>
                                      <IconButton
                                        onClick={() => {
                                          setText(mensagem);
                                          enviarMensagem({
                                            texto: mensagem,
                                            id_mensagem_predefinida,
                                          });
                                          setOpenRespostasRapidas(false);
                                        }}
                                      >
                                        <Send color="info" />
                                      </IconButton>
                                    </Tooltip>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                        </List>
                        {gravarAudio.gravando ? (
                          <>
                            <IconButton
                              style={{ color: dangerColor[0] }}
                              className={classes.iconButton}
                              aria-label="cancelar"
                              onClick={() => gravarAudioF(false)}
                            >
                              <Close />
                            </IconButton>
                            <Timer>
                              <Timer.Minutes formatValue={v => zeroEsquerda(v)} />
                              :
                              <Timer.Seconds formatValue={v => zeroEsquerda(v)} />
                            </Timer>
                            <IconButton
                              style={{ color: successColor[0] }}
                              className={classes.iconButton}
                              aria-label="enviar"
                              onClick={gravarAudioF.bind(this)}
                            >
                              <Check />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            className={classes.iconButton}
                            aria-label="microfone"
                            onClick={gravarAudioF.bind(this)}
                          >
                            <MicNoneTwoTone />
                          </IconButton>
                        )}
                        <Divider className={classes.divider} style={{ height: 30 }} orientation="vertical" />
                        {/*<Tooltip title={"Agendar mensagem"}>*/}
                        <KeyboardDateTimePicker
                          color={'primary'}
                          variant="inline"
                          ampm={false}
                          open={openMessageSchedulerPicker}
                          autoOk={true}
                          onClose={async () => {
                            setOpenMessageSchedulerPicker(false);
                            if (!text)
                              return Swal.fire({
                                icon: 'warning',
                                title: 'A mensagem não pode ser vazia.',
                              });
                            if (new Date(timeMessageSchedulerPicker).getTime() < new Date().getTime())
                              return Swal.fire({
                                icon: 'warning',
                                title: 'A data de envio não pode ser no passado',
                              });

                            const { value } = await Swal.fire({
                              icon: 'question',
                              title: 'Deseja agendar a mensagem?',
                              text: convertDate(timeMessageSchedulerPicker, false, true),
                              showCancelButton: true,
                              confirmButtonText: 'Sim',
                              cancelButtonText: 'Não',
                            });
                            if (!value) return;

                            const { id_whatsapp, uuidConexao, id_whatsapp_conexao } = chat[chatAtivo];
                            const message = {
                              text,
                              dateToSend: timeMessageSchedulerPicker,
                              connection: { id: id_whatsapp_conexao },
                              contact: { id: chatAtivo },
                            };

                            await api.post(`/message/schedule`, message);
                            setText('');
                            Swal.fire({
                              icon: 'success',
                              title: 'Mensagem agendada com sucesso!',
                            });
                          }}
                          TextFieldComponent={props => (
                            <IconButton
                              style={{ padding: 0, margin: '0 5px' }}
                              onClick={() => {
                                if (!text) setOpenModalScheduledMessages(true);
                                else setOpenMessageSchedulerPicker(true);
                              }}
                            >
                              <Schedule color="info" />
                            </IconButton>
                          )}
                          value={timeMessageSchedulerPicker}
                          onChange={date => setTimeMessageSchedulerPicker(date)}
                          disablePast
                          format="yyyy/MM/DD HH:mm"
                        />

                        {/*</Tooltip>*/}

                        <IconButton
                          disabled={sendingMessage}
                          color={'primary'}
                          style={{ color: primaryColor[0] }}
                          className={classes.iconButton}
                          aria-label="enviar"
                          onClick={() => (gravarAudio.gravando ? gravarAudioF(true) : enviarMensagem())}
                        >
                          <Send />
                        </IconButton>
                      </Typography>
                      <div
                        style={{
                          transition: '.2s',
                          position: 'absolute',
                          width: isMobile ? '100%' : 'calc(100% - 60px)',
                          // background: `linear-gradient(60deg, rgba(${hexToRgb('#3CC3FA')}, 0.9), rgba(${hexToRgb('#0098DA')}, 0.9))`,
                          background: `linear-gradient(45deg, #252525, #444444)`,
                          bottom: isMobile ? 65 : 70,
                          right: isMobile ? 0 : 30,
                          opacity: idMensagemResposta.id ? 1 : 0,
                          visibility: idMensagemResposta.id ? 'visible' : 'hidden',
                          zIndex: 2,
                          borderRadius: 5,
                          padding: 15,
                          color: '#FFF',
                          boxShadow:
                            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                        }}
                      >
                        <IconButton
                          style={{
                            color: '#FFF',
                            padding: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          }}
                          className={classes.iconButton}
                          aria-label="Cancelar"
                          onClick={() => setIdMensagemResposta({ id: 0, texto: null })}
                        >
                          <Close />
                        </IconButton>
                        {idMensagemResposta.id && idMensagemResposta.texto}
                      </div>
                    </Container>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : (
            // </Hidden>
            <Hidden xsDown smDown>
              <Grid
                item
                xs={width === 'lg' ? 8 : width === 'md' ? 8 : width === 'xl' && 9}
                className={classes.backgroundChatVazio}
              >
                  {!isPartner && (
                    <img src={backgroundNoChatSelected} style={{ width: 300, marginTop: -100 }} alt={'background'} />
                  )}
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontFamily: 'NunitoRegular',
                    fontSize: 18,
                  }}
                >
                  <br />
                  <strong style={{ color: primaryColor[3] }}>Olá, {me.nome}!</strong>
                  <br />
                  Selecione um chat para enviar mensagens.
                </span>
              </Grid>
            </Hidden>
          )}
        </Grid>
      </main>
      {chatAtivo ? (
        <Drawer
          className={classes.drawer}
          variant={['sm', 'xs'].indexOf(width) === -1 ? 'persistent' : 'temporary'}
          // variant={"persistent"}
          anchor="right"
          open={open}
          classes={{ paper: classes.drawerPaper }}
          onClose={() => setOpen(false)}
        >
          <div className={classes.headerInfo} style={{ width: '335px' }}>
            <div style={{ maxHeight: '12px' }}>
              {isMobile && (
                <div
                  onClick={() => setOpen(false)}
                  style={{
                    float: 'right',
                    cursor: 'pointer',
                    paddingTop: '4px',
                    paddingRight: '16px'
                  }}
                >
                  <Close
                    fontSize='small'
                    color='error'
                  />
                </div>
              )}
            </div>
            <StyledTabs value={valueTabInfo} onChange={handleChangeInfo} variant={'fullWidth'}>
              <StyledTab icon={<Person />} />
              {!chatAtivo.toString().includes('g.us') ? <StyledTab icon={<History />} /> : ''}
              {!chatAtivo.toString().includes('g.us') ? <StyledTab icon={<FaRobot/>}/> : ''}
              {!chatAtivo.toString().includes('g.us') && checkBeta('task-manager') ? <StyledTab icon={<FaTasks/>}/> : ''}
            </StyledTabs>
          </div>
          <div>
            <TabPanel value={valueTabInfo} index={0}>
              <Perfil
                idContato={chatAtivo}
                nome={chatAtivo ? chat[chatAtivo].nome : ''}
                onChangeName={nome => {
                  const tempChat = Object.assign({}, chat);
                  tempChat[chatAtivo].nome = nome;
                  setChat(tempChat);
                }}
                tags={tags}
                getTags={getTags}
                isGroup={chatAtivo.toString().includes('g.us')}
                onSaveName={async () => {
                  const { id_contato, nome } = chat[chatAtivo];
                  const { ok } = await WebService(`/contato/alterarNomeContato`, { id_contato, nome }, 'POST');
                  if (ok) {
                    Swal.fire({
                      title: 'Contato atualizado com sucesso!',
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 2500,
                      timerProgressBar: true,
                    });
                  }
                }}
                protocolo={chatAtivo ? chat[chatAtivo].id_chamado : ''}
                telefone={chatAtivo ? formataNumeroTelefone(chat[chatAtivo].id_whatsapp.split('@')[0]) : ''}
                conexao={chatAtivo ? getConexaoNome(chat[chatAtivo]) : ''}
                conexaoData={chatAtivo ? chat[chatAtivo] : ''}
                ultimosAtendimentos={chatAtivo ? chat[chatAtivo].ultimos_atendimentos : []}
                grupos={grupos}
                connections={conexoes}
                onChangeConnection={async id => {
                  const { id_chamado } = chat[chatAtivo];
                  const { data } = await api.put(`/called/${id_chamado}/connection`, { connection: { id } });
                  const { uuid } = conexoes.find(el => el.id_whatsapp_conexao === id);
                  // console.log({ uuid, id }); // TODO - Não Exibir Logs em produção
                  setChat(chat => {
                    chat[chatAtivo].uuidConexao = uuid;
                    chat[chatAtivo].id_whatsapp_conexao = id;
                    return { ...chat };
                  });
                  await Swal.fire({
                    icon: 'success',
                    title: 'Conexão alterada com sucesso!',
                  });
                }}
              />
              {/*{JSON.stringify(chat[chatAtivo])}*/}
            </TabPanel>
            <TabPanel value={valueTabInfo} index={1}>
              <Historico lastAttendances={chatAtivo ? chat[chatAtivo].ultimos_atendimentos : []} />
            </TabPanel>
            <TabPanel value={valueTabInfo} index={2}>
              <div style={{margin: 10}}>
                <strong>BOTs executados no chamado</strong><br/>
                {![
                  ...([chat[chatAtivo]?.botsData?.runningBOT].filter(el => el) || []),
                  ...(chat[chatAtivo]?.botsData?.history || []).reverse()
                ].length ? (
                    <span>Nada por aqui</span>
                ): ''}
                {([
                  ...([chat[chatAtivo]?.botsData?.runningBOT].filter(el => el) || []),
                  ...(chat[chatAtivo]?.botsData?.history || [])
                ]).map((prop, key) => {
                  return (
                    <div
                      style={{
                        height: 50,
                        // background: '#DDD',
                        borderRadius: 15,
                        padding: '5px 15px',
                        border: '1px solid #EEE',
                        margin: '10px 0',
                        display: 'flex'
                      }}
                    >
                      <div style={{alignContent: "center"}}>
                        {`${prop.flowId} - ${bots.find(el => el.id === prop.flowId)?.name || ''}`}<br/>
                        {/*Início: {convertDate(new Date(), false, true)}*/}
                      </div>
                      <div style={{alignContent: "center"}}>
                        <span style={{
                          width: 110,
                          textAlign: 'center',
                          display: 'block',
                          marginLeft: 20,
                          background: `rgba(${hexToRgb(EFlowStateStatus[prop.flowState].color)}, 0.15)`,
                          color: `rgb(${hexToRgb(EFlowStateStatus[prop.flowState].color)})`,
                          fontWeight: 600,
                          padding: '0 10px',
                          alignContent: 'center',
                          borderRadius: 10,
                          border: `1px solid ${EFlowStateStatus[prop.flowState].color}`,
                          height: 30
                        }}>
                          {EFlowStateStatus[prop.flowState].label}
                        </span>
                      </div>
                      {prop.flowState === 1 ? (
                        <div style={{alignContent: "center"}}>
                          <Tooltip
                            title={'Parar execução'}
                            onClick={async () => {
                              const {value} = await Swal.fire({
                                icon: "question",
                                title: 'Deseja realmente interromperar a execução do BOT?',
                                showCancelButton: true,
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                              });
                              if (!value) return;

                              await api.patch(`/bot/${chat[chatAtivo].id_chamado}/stop`);
                              await Swal.fire({icon: "success", title: 'BOT interrompido com sucesso!'});
                            }}
                          >
                            <Button justIcon simple round color={'danger'} style={{margin: '0 5px', padding: 0, minWidth: 0, width: 25, height: 25}}>
                              <MdOutlineBlock />
                            </Button>
                          </Tooltip>
                          </div>
                      ) : ''}
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel value={valueTabInfo} index={3}>
              <div style={{width: '100%', textAlign: "center"}}>
                <Button color={`hust`} round style={{height: 30}} onClick={async () => {
                  setTaskID(await createTask(chat[chatAtivo].id_chamado));
                  getTasksCalled(chat[chatAtivo].id_chamado);
                }}>
                  Criar nova tarefa
                </Button>
                <Select
                  value={'default'}
                  onChange={async ({target: {value}}) => {
                    const {id_chamado} = chat[chatAtivo];
                    await api.put(`/task/${value}/called`, {called: {id: id_chamado}});
                    await getTasksCalled(id_chamado);
                  }}
                  style={{textAlign: 'left', width: '30vh'}}
                  fullWidth
                >
                  <MenuItem value={'default'} disabled>Adicionar tarefa existente</MenuItem>
                  {tasks.filter(el => el.status !== 'finished' && !tasksCalled.map(prop => prop.id).includes(el.id)).map(prop => {
                    return (
                      <MenuItem key={prop.id} value={prop.id}>
                        #{prop.id} - {prop.title.slice(0, 30)}{prop.title.length > 30 ? '...' : ''}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <hr/>
              {tasksCalled.map((prop, keyTaskCalled) => {
                const {colorHex} = TaskStatusLabel[prop.status];
                const {id_chamado} = chat[chatAtivo];
                // const priority = TaskPriorityLabel[prop.priority]
                return (
                  <div
                    style={{
                      background: `#F5F7FA`,
                      margin: `15px 10px 0`,
                      // border: `1px solid ${colorHex}`,
                      padding: 10,
                      borderRadius: 10,
                      // color: colorHex
                      display: 'flex',
                      position: 'relative'
                    }}
                  >
                    <div style={{position: 'absolute', right: 5, top: 5}}>
                      <Tooltip title={'Remover tarefa do chamado'}>
                        <Button
                          simple round justIcon color={'danger'} size={'sm'}
                          style={{margin: 0, width: 30, height: 30, minWidth: 30, padding: 0}}
                          onClick={() => removeTaskCalled(prop.id, id_chamado).then(() => getTasksCalled(id_chamado))}
                        >
                          <MdClose/>
                        </Button>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        color: '#1A1926',
                        fontFamily: 'sans-serif',
                      }}
                    >
                      <span style={{fontSize: 13, fontWeight: 600, lineHeight: `15px`, width: 'calc(100% - 20px)', display: 'block'}}>
                        #{prop.id} - {prop.title}
                      </span>
                      <p/>
                      <TaskLabelStatus
                          id={prop.id}
                          priority={prop.priority}
                          showFullName onChange={task => {setTasksCalled(tasks => {
                          tasks[keyTaskCalled] = task;
                          return [...tasks];
                        });
                      }}/>
                      {/*<CustomDropdown
                        buttonText={priority.label}
                        buttonProps={{
                          // simple: true,
                          round: true,
                          style: {marginBottom: "0", height: 24, width: 90},
                          color: priority.color
                        }}
                        dropdownList={Object.keys(TaskPriorityLabel).map(prop => ({
                          value: prop,
                          label: TaskPriorityLabel[prop].label
                        }))}
                        noLiPadding={true}
                        onClick={priority => changeTask(prop.id, {priority})}
                      />
                      Prioridade {priority.label}<br/>*/}
                      <div style={{display: 'flex'}}>
                        {prop.dateDue ? (
                          <div style={{
                            minWidth: 140,
                            display: 'flex',
                            alignItems: 'center',
                            background: '#CCC',
                            borderRadius: 7,
                            padding: '0 5px'
                          }}>
                            <FaClock color={'#555'}/>
                            <span style={{marginLeft: 5}}>{convertDate(prop.dateDue, false, true)}</span>
                          </div>
                        ) : ''}
                        <div style={{width: '100%'}}>
                          {prop.user ? (
                            <ViewUserActions
                              style={{float: 'right'}}
                              name={prop.user.name}
                              profilePicture={prop.user.profilePicture}
                              showRemove
                              onDelete={async () => {
                                await removeUser(prop.id);
                                setTasksCalled(tasks => {
                                  delete tasks[keyTaskCalled].user;
                                  return [...tasks];
                                });
                              }}
                            />
                          ) : (
                            <Tooltip title={'Definir Responsável'}>
                              <Button color={'primary'} justIcon size={'sm'} round onClick={async () => {
                                const task = await addRecipient(prop.id, usuarios);
                                console.log({task});
                                setTasksCalled(tasks => {
                                  tasks[keyTaskCalled] = task;
                                  return [...tasks];
                                });
                              }}>
                                <FaPlus/>
                              </Button>
                            </Tooltip>
                          )}
                          {/*<img src={getFotoLink(prop?.user?.profilePicture)} style={{width: 25, borderRadius: '50%'}}/>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </TabPanel>
          </div>
        </Drawer>
      ) : (
        ''
      )}
    </div>
  )
}

NewChat.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(NewChat);
// export default NewChat;
